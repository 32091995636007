import React, { useEffect, useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Form,
  Button,
  Dropdown,
  DropdownButton,
  Row, Col, InputGroup,
} from "react-bootstrap";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth, AuthProvider } from "../contexts/AuthContext";
import { auth, fires} from "../firebase"

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import TourPurchaseTicket from "./TourPurchaseTicket"
import Login from "./Login";
import Signup from "./Signup";
import NotFound from "./NotFound";
import EventDetails from "./EventDetails";
import EventRegistration from "./EventRegistration";
import EventSignup from "./EventSignup";
import UserDetails from "./UserDetails";
import LiveEvent from "./LiveEvent";
import EventExpressCheckIn from "./EventExpressCheckIn";
import EventOrganizer from "./EventOrganizer";
import ForgotPassword from "./ForgetPassword";
import EventFeedback from "./EventFeedback";
import AllEvents from "./AllEvents";
import Home from "./Home/Home";
import EventLuckyDrawForm from "./EventLuckyDrawForm";
import EventTicketValidation from "./EventTicketValidation";
import PostDetails from "./PostDetails";
import CreateGroups from "./Groups/CreateGroups";
import CreateGroupInstructions from "./Groups/CreateGroupInstructions";
import EventFollowup from "./EventFollowup";
import EventWall from "./Events/EventWall";
import EventInfo from "./Events/EventInfoAndService";
import EventService from "./Events/EventService";
import EventJourney from "./Events/EventJourney";
import EventSponsorForm from "./Home/EventSponsorForm";

import Article from "./Article";
import CreatePost from "./Posts/CreatePost";
import EventOrgMemberCenter from "./Events/EventOrgMemberCenter";

import EventTicket from "./Events/EventTicket";
import EventOrganizerAllEvents from "./EventOrganizerAllEvents";

import EventTestRegistration from "./EventTestRegistration";

import EventPurchaseTicket from "./EventPurchaseTicket";
import EventPaymentConfirmation from "./EventPaymentConfirmation";
import EventPaymentCancel from "./EventPaymentCancel";
import { useTranslation } from 'react-i18next';
import EventSchedule from "./EventSchedule";
import Pricing from "./Home/Pricing";
import LuckyDrawSignup from "./Promos/LuckyDrawSignup";
import Newsletter from "./EmailList";
import OrganizerMembershipRequest from "./Groups/OrganizerMembershipRequest";
import GuestPass from "./Events/GuestPass";
import EventTicketConfirmation from "./Events/EventTicketConfirmation";
import ClientForm from "./Home/ClientForm";
import EventRequest from "./EventRequest";
import EventNewPaymentConfirmation from "./Events/EventNewPaymentConfirmation";


const lngs = [
  { code: "en", native: "English" },
  { code: "zh", native: "简体中文" },
];



function onClickBtn(){
  window.open("https://leyuan.page.link/app")
}

const logoImg = "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/evtgo_banner_white.png"

const navBarrButton = () => {
  return <div>
      <Button style={{
      backgroundColor: "transparent", 
      borderColor: "transparent", 
      height: '35px',
      marginLeft: '5px',
      fontSize: '15px',
      fontWeight: 'bold',
      color: 'white',
      borderRadius: '15px'}} 
      onClick={()=>{
        window.location.href="/client-form"
      }}>Host Events</Button>
      <Button style={{
      backgroundColor: "#f6AC19", 
      borderColor: "transparent", 
      height: '35px',
      width: '90px',
      marginLeft: '5px',
      fontSize: '15px',
      fontWeight: 'bold',
      borderRadius: '15px',
      color: 'black',
      }} 
      hidden={true}
      onClick={()=>{
        if (auth.currentUser == null){
          window.location.href="/login"
        }
      }}>{auth.currentUser == null ? 'Login' : 'Account'}</Button>
      </div>
}


function App() {
  
  return (

    <div>
      <Navbar bg="#f6AC19" variant="dark" expand="lg" sticky="top" style={{height: '60px', backgroundColor: '#1F2020'}} hidden={
        window.location.href.includes('/event-wall/') || window.location.href.includes('/payment-success/') }>
            <Container fluid>
              <Navbar.Brand href="/">
              <img
              alt=""
              src={logoImg}
              width={isMobile ? "102" : "170"}
              height={isMobile ? "15" : "25"}
              className="d-inline-block align-center"
            />
            </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" hidden={isMobile}/>
        <Navbar.Collapse id="basic-navbar-nav" hidden={isMobile}>
          <Nav className="me-auto">
            {/* <Nav.Link href="/home" style={{fontSize: '15px', fontWeight: 'bold', color: 'white'}}>About us</Nav.Link>
            <Nav.Link href="/client-form" style={{fontSize: '15px', fontWeight: 'bold', color: 'white'}}>Host event</Nav.Link>
            <Nav.Link href="/help" style={{fontSize: '15px', fontWeight: 'bold', color: 'white'}}>Help</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
          {
            navBarrButton()
          }
            </Container>
          </Navbar>
      <AuthProvider>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<UserDetails />} />
            <Route path="/live-event-dashboard/:id" element={<LiveEvent />} />
            <Route path="/live-event-dashboard/:id/:lang" element={<LiveEvent />} />
            <Route path="/payment-success/:id" element={<EventPaymentConfirmation />} />
            <Route path="/payment-confirmation/:paymentId" element={<EventNewPaymentConfirmation />} />
            <Route path="/payment-cancel/:id" element={<EventPaymentCancel />} />
            <Route path="/create-post/" element={<CreatePost />} />
            <Route path="/create-post/:id" element={<CreatePost />} />
            <Route path="/member-center/:orgId" element={<EventOrgMemberCenter />} />
          </Route>

          <Route path="/explore" element={<AllEvents />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/become-eventgo-partner" element={<EventSponsorForm />} />
          <Route path="/become-eventgo-partner/:lang" element={<EventSponsorForm />} />
          <Route path="/event-ticket-confirmation/:ticketId" element={< EventTicketConfirmation />} />
          <Route path="/event-purchase-ticket/:id" element={<EventPurchaseTicket />} />
          <Route path="/event-purchase-ticket/:id/request/:requestId" element={<EventPurchaseTicket />} />
          <Route path="/event-purchase-ticket/:id/:lang" element={<EventPurchaseTicket />} />
          <Route path="/tour-purchase-ticket/:id" element={<TourPurchaseTicket/>}/>
          <Route path="/tour-purchase-ticket/:id/:lang" element={<TourPurchaseTicket/>}/>
          <Route path="/event-registration/:id" element={<EventRegistration />} />
          <Route path="/event-registration/:id/:lang" element={<EventRegistration />} />
          <Route path="/event-test-registration/:id/:lang" element={<EventTestRegistration />} />
          <Route path="/event-signup/:id" element={<EventSignup />} />
          <Route path="/event-request/:id" element={<EventRequest />} />
          <Route path="/event-express-checkin/:id/:checkInCode" element={<EventExpressCheckIn />} />
          <Route path="/event-lucky-draw/:id/:drawId" element={<EventLuckyDrawForm/>} />
          <Route path="/event-lucky-draw/:id/:drawId/:langCode" element={<EventLuckyDrawForm/>} />
          <Route path="/create-group" element={<CreateGroups/>} />
          <Route path="/client-form" element={<ClientForm/>} />

          <Route path="/ticket-id/:ticketId" element={<EventTicket />} />
          <Route path="/event-wall/:id" element={<EventWall />} />

          <Route path="/follow-up/:id" element={<EventFollowup />} />

          <Route path="/:id" element={<EventDetails />} />
          <Route path="/guest-pass/:id" element={<GuestPass />} />
          <Route path="/:id/:lang" element={<EventDetails />} />
          <Route path="/organizer/:id" element={<EventOrganizer />} />
          <Route path="/event-info/:id" element={<EventInfo />} />
          <Route path="/journey/:id" element={<EventJourney />} />
          <Route path="/events/:id" element={<EventOrganizerAllEvents />} />
          <Route path="/schedule/:id" element={<EventSchedule />} />
          <Route path="/post/:id" element={<PostDetails />} />
          <Route path="/article/:id" element={<Article />} />
          <Route path="/login" element={<Login />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route path="/subscribe/:emailId" element={<Newsletter />} />
          <Route path="/unsubscribe/:emailId" element={<Newsletter />} />
          <Route path="/login/:actionId/:actionRef" element={<Login />} />
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/signup/:actionId/:actionRef" element={<Signup/>}/>
          <Route path="/lucky-draw-signup" element={<LuckyDrawSignup/>}/>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/submit-feedback/:id" element={<EventFeedback />} />
          <Route path="/home" element={<Home />} />
          <Route path="/ticket-checker" element={<EventTicketValidation />} />
          <Route path="/service/:serviceId" element={<EventService />} />
          <Route path="/membership-request/:orgId" element={<OrganizerMembershipRequest />} />
          <Route exact path="/" element={<AllEvents />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
