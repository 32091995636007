import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
  Dropdown,
  DropdownButton,
  Spinner,
  FloatingLabel,
  Badge,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams, Route } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { fires, auth, db } from "../firebase";
import { payShoppingCartTourTicket, sendEventTicketEMTPaymentEmail } from "../contexts/API";
import firebase from "firebase/app";
import "firebase/firestore";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { validate } from "email-validator";
import uuid from "react-uuid";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Autocomplete from "react-google-autocomplete";

import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';



export default function TourPurchaseTicket() {
  
    const { currentUser, logout, } = useAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showCompleted, setShowCompleted] = useState(false)
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "50%";

  const { t, i18n } = useTranslation();

  const lngs = [
    { code: "en", native: "English" },
    { code: "zh", native: "简体中文" },
  ];

  let { id, lang } = useParams();
  var currentUID = ""

  const [currentViewId, setCurrentViewId] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //upload image
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState("");
  const [uploadS, setUploadS] = useState("请点击上传图片开始上传")
  const [uploadedURL, setUploadedURL] = useState('');
  const [fileUploadIndex, setFileUploadindex] = useState(-1);

  const [showUpload, setShowUpload] = useState(false);
  const handleUploadClose = () => setShowUpload(false);
  const handleUploadShow = () => setShowUpload(true);

  const [selectedExhibition,setSelectedExhibition] = useState("")

  const [eventCart, setEventCart] = useState([
    
  ])
  const [shoppingCart, setShoppingCart] = useState({

  })
  const [shoppingCartRuleAppliedPriceIds, setShoppingCartRuleAppliedPriceIds] = useState([])

  const [registerType, setRegisterType] = useState(0)
  const [eventDetails, setEventDetails] = useState({
    title: '',
    description: '',
    photoURL: '',
    id: '',
    signup: false,
    ticketStatus: false,
    descriptions: {
      zh: [],
      en: []
    },
    refundPolicy: '',
    type: 'free-event',
    startDate: firebase.firestore.Timestamp.fromDate(new Date()),
    endDate: firebase.firestore.Timestamp.fromDate(new Date())
  });
  const [eventOptions, setEventOptions] = useState({
    'allow-multiple': false,
    'comment':false,
    'draw':false,
    'picture':true,
    'max': 500,
    'multipleDay': false,
    'coupon': false,
    'multi-ticket-checkout': true,
  })
  const [availableTickets, setAvailableTickets] = useState(10)

  const [eventTicketSelected, setEventTicketSelected] = useState(false)
  const [eventPriceOptions, setEventPriceOptions] = useState(
      {
      priceType: 'FREE', 
      price: 0, 
      priceOriginal: 0, 
      priceTax: 0,
      quan: 1,
      priceId: '', 
      productId: '', 
      priceCurrency: 'CAD', 
      priceEMTEmail: '',
      priceDescription: 'Ticket',
      id: '',
      max: 10,
      min: 1,
      count: 500,
      'form-per-attendee': false,
      priceIncludeTax: false,
      startDate: firebase.firestore.Timestamp.fromDate(new Date()),
      endDate: firebase.firestore.Timestamp.fromDate(new Date()),
      startDateStr: "",
      startTimeStr: "",
      endDateStr: "",
      endTimeStr: "",
      couponCode: "",
      remaining: 500,
      saleStart: firebase.firestore.Timestamp.fromDate(new Date()),
      saleEnd: firebase.firestore.Timestamp.fromDate(new Date()),
    })

  const [orgDetails, setOrgDetails] = useState({
    id: ''
  });
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [eventAdditionalQuestions, setEventAdditionalQuestions] = useState([
    {
      title: '',
      placeholder: '',
      id: '',
      order: -1,
      optionType: 'text',
      valueType: 'text',
      required: false,
      options: [],
      optionMore: false,
      value: '',
      allowMultiple: false,
      styles: {}
    }
  ])
  const [eventMemberDetails, setEventMemberDetails] = useState({
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    ticketId: '',
    quantity: 1,
  })

  const [eventPrices, setEventPrices] = useState([

  ])

  const [exhibitions,setExhibitions] = useState([
    {
    title: "",
    description: "",
    imageURL: "",
    id: "",
    startDate: firebase.firestore.Timestamp.fromDate(new Date()),
    endDate: firebase.firestore.Timestamp.fromDate(new Date())}
  ])

  const [eventDefaultPrice, setEventDefaultPrice] = useState({
    price: 0,
    priceType: 'CREDIT',
    priceCurrency: 'CAD',
    priceDescription: 'Early Bird',
    priceId: '',
    max: 10,
    min: 1,
    'form-per-attendee': false,
    startDate: firebase.firestore.Timestamp.fromDate(new Date()),
    endDate: firebase.firestore.Timestamp.fromDate(new Date()),
  })
    
    const [totalTicketCount, setTotalTicketCount] = useState(0)

  const eventRegisterEmailRef = useRef()
  const eventRegisterEmailConfirmRef = useRef()
  const eventRegisterFirstNameRef = useRef()
  const eventRegisterLastNameRef = useRef()
  

  const eventLoginEmailRef = useRef()
  const eventLoginPasswordRef = useRef()

  const eventPurchaseFirstNameRef = useRef()
  const eventPurchaseLastNameRef = useRef()

  const [pickDateRange, setPickDateRange] = useState([])
  const [selectedDates, setSelectedDates] = useState([])
  const [selectedDay, setSelectedDay] = useState("")
  const [selectStep,setSelectStep] = useState(0)
    
  const [ticketTypeCount, setTicketTypeCount] = useState({})
  const [totalAmount,setTotalAmount] = useState(0.0)
  async function calculateCartAmount(){
    let tAmount = 0
    Object.values(shoppingCart).forEach((cartItm) => {
      const unit_price = cartItm.price
      const quantity = cartItm.quantity
      if (unit_price != null && quantity != null){
        tAmount += unit_price * quantity
      }
    })
    setTotalAmount(tAmount)
  }

  async function checkTicketPrices(eventId, maxT){
    await fetch("https://us-central1-blink-574af.cloudfunctions.net/eventsAPICheckTicketStatus?event_id="+eventId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    })
    .then(response => response.json())
    .then((data) => {
      if (data.ticketsCount != null){
        setTotalTicketCount(data.ticketsCount)
        if (maxT - data.ticketsCount < 10 && maxT - data.ticketsCount > 0){
          setAvailableTickets(maxT - data.ticketsCount)
        }
      }
      if (data.currentPrices != null){
        console.log("Current Prices " + JSON.stringify(data.currentPrices))
      }
      if (data.currentTickets != null){
        let ticketCC = data.currentTickets
        setTicketTypeCount(ticketCC)
        console.log("Current Tickets: " + JSON.stringify(ticketCC))
      }
      
    })
}

  async function checkCurrentTickets(maxT){
    //check if we have tickets left
    
    checkTicketPrices(id,maxT)
  }


  function getBlackOutDatesMonday(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        if (currentDate.getDay() == 1){
            dateArray.push((new Date(currentDate)).toLocaleDateString('en'));
        }
        currentDate.setDate(currentDate.getDate() + 1)
    }
    return dateArray;
}

const [exhibitionSelectedIds, setExhibitionSelectedIds] = useState([])
const [exhibitionSelectedNames, setExhibitionSelectedNames] = useState([])

const selectExhibitionSection = () => {
  return <div>
      {
        exhibitions.map((exitem) => {
          return <Card className="mt-2" hidden={selectStep > 0}>
            <Card.Body>
              <Card.Title>{exitem.title}</Card.Title>
              <Card.Text>{exitem.description}</Card.Text>
              <Card.Text>Sale ends at <b>{exitem.endDate.toDate().toDateString()}</b></Card.Text>
              <Button 
              variant={exhibitionSelectedIds.includes(exitem.id) ? "success" : "outline-success"}
              onClick={()=>{
                if (!exhibitionSelectedIds.includes(exitem.id)){
                  let exhiIds = exhibitionSelectedIds
                  let exhiNames = exhibitionSelectedNames

                  exhiIds.push(exitem.id)
                  exhiNames.push(exitem.title)

                  setExhibitionSelectedIds(exhiIds)
                  setExhibitionSelectedNames(exhiNames)

                  //filter event prices
                  let filterPrices = eventPrices.filter((priceItm) => {
                    let filterId = ""
                    if (priceItm.filterId != null){
                      filterId = priceItm.filterId
                    }
                    return exhiIds.includes(filterId)
                  })

                  setEventPrices(filterPrices)

                  setSelectStep(1)

                  setSuccess("Selected Exhibition: " + exitem.title)
                  //filter tickets
                  
                }
                
              }}>{exhibitionSelectedIds.includes(exitem.id) ? "Selected" : "Select Exhibition"}</Button>
            </Card.Body>
          </Card>
        })
      }
  </div>
}

const selectTicketsSelection = () => {
    return <div>
    {
      eventPrices.map((priceItm, index) => {
        let ticketMax = 10
        let ticketMin = 1
        let ticketAddress = ""
        let ticketStartDate = ""
        let ticketTotalCount = 500
        let ticketPriceTax = 0
        let ticketNotes = ""
        if (priceItm.max != null){
          ticketMax = priceItm.max
        }
        if (priceItm.min != null){
          ticketMin = priceItm.min
        }
        if (priceItm.address != null){
          ticketAddress = priceItm.address
        }
        if (priceItm.startDateString != null){
          ticketStartDate = priceItm.startDateString
        }
        if (priceItm.count != null){
          ticketTotalCount = priceItm.count
        }
        else{
          ticketTotalCount = 500
        }
        if (priceItm.priceTax != null){
          ticketPriceTax = priceItm.priceTax
        }
        if (priceItm.priceNotes != null){
          ticketNotes = priceItm.priceNotes
        }
        

        let remainingT = ticketTotalCount
        if (ticketTypeCount[priceItm.id] != null){
          remainingT = ticketTotalCount - ticketTypeCount[priceItm.id]
        }
        else{
          remainingT = ticketTotalCount
        }
        
        return <Card  style={{marginTop: '25px',}}>
          <Card.Body className="d-flex align-items-center justify-content-between">
          <div>
            <Card.Text style={{fontSize: 12, fontWeight: 'normal', margin: 0}}>{i18n.language == 'en' ? "Ticket Type" : "门票种类"}{" "}<Badge bg="success" hidden={!Object.keys(shoppingCart).includes(priceItm.id)}>{i18n.language == 'en' ? "In Cart" : "已在购物车"}</Badge></Card.Text>
            <Card.Text style={{fontSize: 20, fontWeight: 'normal', margin: 0}}>{priceItm.priceDescription}</Card.Text>
            <Card.Text style={{fontSize: isMobile ? 25 : 28, fontWeight: 'bold', margin: 0}}>{priceItm.priceCurrency} ${priceItm.price}</Card.Text>
            <Card.Text style={{fontSize: 12, fontWeight: 'normal', margin: 0, color: 'gray'}} hidden={ticketNotes == ""}>{ticketNotes}</Card.Text>
          </div>
          <DropdownButton
              variant="outline-dark"
              title={t("event_details_registration_header_quantity") + ": " + priceItm.quantity}
              style={{marginLeft: '15px'}}
              drop={'down'}
              onSelect={(e) => {
                if (e == 'more-option'){
                  
                }
                else{
                  const quan = parseInt(e)
                if (quan >= ticketMin && quan <= ticketMax){
                  var currentPrices = eventPrices
                  if (index >= 0 && index < eventPrices.length){
                    currentPrices[index].quantity = quan
                    setEventPrices(currentPrices)
                    if (i18n.language == 'zh'){
                      setSuccess("购买 " + priceItm.priceDescription + " " + quan + "张票")
                    }
                    else{
                      setSuccess("Buy " + priceItm.priceDescription + " tickets for " + quan)
                    }

                    
                  }
                }
                else {
                  
                }
                }
              }}
              >
                {
                  Array.from({length: ticketMax}, (_, i) => i + 1).map((itm) => {
                    return <Dropdown.Item eventKey={`${itm}`} hidden={ticketMax < itm || ticketMin > itm || remainingT < itm}>{itm}</Dropdown.Item>
                  })
                }
                
              </DropdownButton>
          </Card.Body>
          
          <Button className="border-0" disabled={priceItm.quantity == 0}  style={{marginLeft: '15px', marginRight: '15px', marginBottom: '15px', borderRadius: '0px', fontSize: '17px', fontWeight: 'bold', backgroundColor: "#f6AC19", color: 'black'}} onClick={()=>{
            if (index >= 0 && index < eventPrices.length && remainingT >= priceItm.quantity){
              var currentPrice = priceItm

              var formPerAttendee = false
              if (currentPrice['form-per-attendee'] != null){
                if (currentPrice['form-per-attendee'] == true){
                  formPerAttendee = true
                }
              }
              var priceIncludeTax = false
              var priceTax = 0
              if (currentPrice.priceIncludeTax != null){
                priceIncludeTax = currentPrice.priceIncludeTax
              }
              if (currentPrice.priceTax != null){
                priceTax = currentPrice.priceTax
              }

              let currentCart = eventCart
              //look for price item id

              let sCart = shoppingCart
              let evaluationResult =
              evaluatePricingRules(
                currentPrice.id,
                currentPrice.priceRules,
                currentPrice.quantity)

                

              if (currentPrice.id != "" && currentPrice.id != null && evaluationResult.result){
                if (Object.keys(sCart).includes(currentPrice.id)){
                  //cart include
                  sCart[currentPrice.id].quantity = currentPrice.quantity
                  setShoppingCart(sCart)
                }
                else{
                  sCart[currentPrice.id] = {
                    quantity: currentPrice.quantity,
                    price: currentPrice.price,
                    priceTax: priceTax,
                    priceCurrency: currentPrice.priceCurrency,
                    priceId: currentPrice.priceId,
                    priceDescription: currentPrice.priceDescription,
                    priceType: currentPrice.priceType,
                    priceItemId: currentPrice.id,
                    id: currentPrice.id,
                  }
                  setShoppingCart(sCart)
                }

                currentCart = Object.values(sCart)
                setEventCart(currentCart)
                calculateCartAmount()
                setSuccess(`Cart item added: ${currentPrice.priceDescription} * ${currentPrice.quantity}`)
                alert(`Items added to cart: ${currentPrice.priceDescription} * ${currentPrice.quantity}`)
              }
              else{
                if (!evaluationResult.result){
                  //evaluation Result
                  //check if need to clear cart
                  if (evaluationResult.clearCart){
                    setEventCart(currentCart)
                    calculateCartAmount()
                    setSuccess(`${evaluationResult.message}`)
                    alert(evaluationResult.message)
                  }
                  else{
                    setSuccess(`${evaluationResult.message}`)
                    alert(evaluationResult.message)
                  }
                }
                else{
                  alert("cart id not found")
                }

              }

            }
            else{
              alert('Ticket purchase failed')
            }
            setSuccess("")
          }}>{i18n.language == 'en' ? 'Add to cart' : '添加到购物车'}</Button>
        </Card>
      })
    }
    </div>
  
}

  const selectDateSection = () => {
    let startDate = firebase.firestore.Timestamp.fromDate(new Date())
    let endDate = firebase.firestore.Timestamp.fromDate(new Date())
    if (exhibitionSelectedIds.length > 0){
      let selectExbition = exhibitionSelectedIds[0]
      let exhibitionSet = exhibitions.filter((exhi) => {
        return exhi.id == selectExbition
      })
      if (exhibitionSet.length > 0){
        let exbitionItm = exhibitionSet[0]
        startDate = exbitionItm.startDate
        endDate = exbitionItm.endDate
      }
      else{
        startDate = eventDetails.startDate
        endDate = eventDetails.endDate
      }
    }
    else{
      startDate = eventDetails.startDate
      endDate = eventDetails.endDate
    }
    const blackoutDates = getBlackOutDatesMonday(startDate.toDate(),endDate.toDate())
    
    return <div hidden={selectStep < 1}>
      <Card.Text>1. Select day for event <b>{selectedDay}</b></Card.Text>
      <div className="d-flex align-items-center justify-content-center">
      <Calendar 
      onChange={(value,event)=>{
        const dateStr = value.toLocaleDateString('en')
        if (blackoutDates.includes(dateStr)){
            alert("The date you selected - " + dateStr + " is not available")
        }
        else{
            setSelectedDay(dateStr)
            setSuccess("Date selected - " + dateStr)
            setSelectStep(2)
            alert("Date selected - " + dateStr)
        }
        
      }}
      minDate={(new Date()) > startDate.toDate() ? (new Date()) : startDate.toDate()}
      maxDate={endDate.toDate()}
       />
      </div>
       <Card.Text style={{marginTop: '15px'}}>2. Add tickets to cart</Card.Text>
    </div>

  }
  
  const uploadPicModal = () => {
    return <Modal
    show={showUpload}
    onHide={() => {
      handleUploadClose()
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>
        Upload File
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {selectedImage && (
                <div>
                <img 
                alt="not found" 
                width={"250px"} 
                src={URL.createObjectURL(selectedImage)} 
                />
                <br />
                <Alert 
                variant="primary"
                >{uploadS}
                </Alert>
                <div>
                  <button 
                  onClick={()=>setSelectedImage(null)}
                  >移除</button>
                  <button 
                  onClick={handleImageSubmit}
                  >上传</button>
                </div>
                </div>
)}
<input
type="file"
name="coverImage"
title="选择照片"
onChange={handleImageChange}
/>
<br /><br />

    </Modal.Body>
    <Modal.Footer></Modal.Footer>
    </Modal>
  }

  const [showCoupon, setShowCoupon] = useState(false)
  const applyCouponModal = () => {
    return <Modal
    show={showCoupon}
    onHide={() => {
      setShowCoupon(false)
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>
        {i18n.language == 'en' ? 'Apply Coupon' : '使用优惠码'}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group>
        <FloatingLabel label={i18n.language == 'en' ? 'Enter coupon code' : '输入优惠码'}>
          <Form.Control type="text" autoCapitalize={true} ref={couponRef} onChange={(e) => {
            
          }}/>
        </FloatingLabel>
      </Form.Group>
      <Form.Group style={{marginTop: '15px'}}>
        <Button variant="dark" onClick={(e)=>{
          const couponEntered = couponRef.current.value
          if (couponEntered != ""){
            const coup = couponEntered.toUpperCase()
            eventPromoValidation(coup)
            setShowCoupon(false)
          }
        }}>{i18n.language == 'en' ? 'Apply Coupon' : '使用优惠码'}</Button>
      </Form.Group>
    </Modal.Body>
    </Modal>
  }

  const [userCoupon, setUserCoupon] = useState({
    coupon: '',
    couponType: '',
  })
  const couponRef = useRef();
  async function eventPromoValidation(couponCode){
    const dataBody = `coupon_code=${couponCode}&event_id=${id}`;
    fetch("https://eventsapicheckpromocode-verqhozorq-uc.a.run.app?"+dataBody,{
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', }
    }).then((response) => {
      response.json().then((data) => {
        var dataS = {
          success: false, 
          couponType: "",
          couponCode: '',}
        dataS = data
        if (dataS.success){
          if (dataS.couponType == 'FREE'){
            //FREE Order
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
            })
            let cOption = eventPriceOptions
            cOption.priceType = 'FREE'
            cOption.price = 0
            cOption.priceTax = 0
            cOption.couponCode = couponCode
            if (i18n.language == 'en'){
              cOption.priceDescription = "Coupon (FREE)"
            }
            else{
              cOption.priceDescription = "折扣票(免费)"
            }
            setEventPriceOptions(cOption)
            
            window.alert('Coupon is now applied')
          }
          else{
            window.alert('Coupon is invalid')
          }
        }
        else{
          window.alert('Coupon is invalid')
        }
      })
    })
  }

  async function eventRegistrationWithoutForm(email, firstName, lastName, quan, eventId, priceDesc, selectedDate, promoC){
    
    const dataBody = `email=${email}&first_name=${firstName}&last_name=${lastName}&quantity=${quan}&event_id=${eventId}&price_desc=${priceDesc}&selected_date=${selectedDate}&promo_code=${promoC}`;
    fetch("https://eventsapiaddeventmembers-verqhozorq-uc.a.run.app?"+dataBody,{
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', }
    }).then((response) => {
      response.json().then((data) => {
        var dataS = {success: false, ticketId: ''}
        dataS = data
        if (dataS.success != null){
          if (dataS.ticketId != null && dataS.ticketId != ""){
            signInAfterOrder(firstName,lastName,email,eventId,dataS.ticketId)
          }
          else{
            if (dataS.success){
              signInAfterOrder(firstName,lastName,email,eventId,"")
            }
            else{
              window.alert("Registration failed - no success status 500")
            }
          }
          
        }
        else{
          window.alert("Registration failed - no call back status 500")
        }
      })
    })
    
  }

  async function eventRegistrationWithForm(email, firstName, lastName, quan, eventId, formContent, priceDesc, selectedDate, promoC){
    //form contents need to be json
    const dataBody = `email=${email}&first_name=${firstName}&last_name=${lastName}&quantity=${quan}&event_id=${eventId}&price_desc=${priceDesc}&selected_date=${selectedDate}&promo_code=${promoC}`;
    fetch("https://eventsapiaddeventmembers-verqhozorq-uc.a.run.app?"+dataBody,{
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', }
    }).then((response) => {
      response.json().then((data) => {
        var dataS = {success: false, ticketId: ""}
        dataS = data
        
        if (dataS.success != null){
          if (dataS.success){
            if (dataS.ticketId != null && dataS.ticketId != ""){

              fires.collection('events').doc(eventId).collection('formSubmissions').add({
                forms: formContent,
                email: email,
                ticketId: dataS.ticketId,
                timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
              })

              fires.collection('events').doc(eventId).collection('members').doc(email).update({forms: formContent}).then(()=>{
                
                signInAfterOrder(firstName,lastName,email,eventId,dataS.ticketId)
              })
            }
            else{

              fires.collection('events').doc(eventId).collection('formSubmissions').add({
                forms: formContent,
                email: email,
                timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
              })

              fires.collection('events').doc(eventId).collection('members').doc(email).update({forms: formContent}).then(()=>{
                
                signInAfterOrder(firstName,lastName,email,eventId,"")
              })
            }
          }
          else{
            window.alert("Registration failed - no success status 500")
          }
        }
        else{
          window.alert("Registration failed - no call back status 500")
        }
      })
    })
    
  }

  async function signInAfterOrder(firstName,lastName,email, eventId, ticketId){
    if (auth.currentUser == null){
      auth.signInAnonymously().then((userCre)=>{
        const uid = userCre.user.uid
        fires.collection('users').doc(uid).set(
          {
            'firstName':firstName,
            'lastName':lastName,
            'email':email,
            'currentEvent': eventId,
            'lang': i18n.language,
            'userName': firstName + " " + lastName,
            'name': firstName + " " + lastName,
            'profileURL':'',
            'isAnonymous': userCre.user.isAnonymous,
            'timestamp': firebase.firestore.Timestamp.fromDate(new Date()),
          }).then(()=>{
            window.location.href = "/event-ticket-confirmation/" + ticketId
          })
      })
    }
    else{
      window.location.href = "/event-ticket-confirmation/" + ticketId
    }
  }


  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  const routeBackEvents = (eId) => {
    let path = '/'+eId ; 
    window.location.href = path
  }

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

    function uploadImage(file) {
      fetch('https://r2-worker.sam-12e.workers.dev/', {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': file.type
        },
        body: file
      })
        .then((response) => response.text())
        .then((data) => {
          const downloadURL = "https://pub-e888c0363b7c412caf77f22ba84fcb86.r2.dev/" + data
          //
          if (fileUploadIndex >= 0 && fileUploadIndex < eventAdditionalQuestions.length){

            var eventQues = eventAdditionalQuestions
            eventQues[fileUploadIndex].value = downloadURL
            setEventAdditionalQuestions(eventQues)
            
            setSelectedImageURL(downloadURL)
            setSelectedImage(null)
            setUploadedURL(downloadURL)
          }
          
          
        }) // 
        .catch((error) => console.error(error));
    }
    
    const handleImageChange = (e) => {
      e.preventDefault()
      const file = e.target.files[0];
      setSelectedImage(file)
    }
  
    const handleImageSubmit = (e) => {
      e.preventDefault()
      const file = selectedImage;
      if (!file) return;
      if (fileUploadIndex >= 0 && fileUploadIndex < eventAdditionalQuestions.length){
        uploadImage(file)
      }
      
      
    }

    function handleEventEmtPayment(userEmail,userName,firstName,lastName){
      var formAnswers = []
      eventAdditionalQuestions.forEach((eventQItm) => {
        formAnswers.push(
          {
          title: eventQItm.title, 
          value: eventQItm.value,
          id: eventQItm.id,
        })
      })

      const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
      const ua = window.navigator.userAgent.toLowerCase();
      fires
                .collection("events")
                .doc(id)
                .collection("members")
                .doc(userEmail)
                .set({
                  name: userName,
                  checkinStatus: false,
                  payment: false,
                  firstName: firstName,
                  lastName: lastName,
                  quantity: eventPriceOptions.quan,
                  work: '',
                  wechat: '',
                  fromUA: ua,
                  timestamp: timestampNow,
                  email: userEmail,
                  paymentType: 'EMT',
                  viewId: currentViewId,
                  lang: i18n.language,
                  forms: formAnswers,
                })
                .then(() => {
                  sendEventTicketEMTPaymentEmail(
                    userEmail,
                    eventDetails.title,
                    id,
                    eventPriceOptions.priceEMTEmail,
                    eventPriceOptions.price,
                    '',
                    '',
                    userName,
                    eventDetails.user.userOrg,
                    i18n.language,
                    eventPriceOptions.quan)

                    setShowCompleted(true)
                });
    }


    function handleEventCreditCardPayment(userEmail,firstName,lastName, calculateTax){
      const uniqueUUID = uuid()
      var formAnswers = []
      eventAdditionalQuestions.forEach((eventQItm) => {
        formAnswers.push(
          {
          title: eventQItm.title, 
          value: eventQItm.value,
          id: eventQItm.id,
        })
      })
      let priceDesc = ""
      let applyCoupon = false
      priceDesc = eventPriceOptions.priceDescription
      if (selectedDates.length > 0){
        const dateStr = selectedDates[0]
        priceDesc += ` (${dateStr})`
    }
    if (eventOptions.coupon != null){
      if (eventOptions.coupon){
        applyCoupon = true
      }
    }

    let paymentItems = []
    eventCart.forEach((eventCartItm) => {
      if (eventCartItm.priceType != "FREE"){
        paymentItems.push({
          quantity: eventCartItm.quantity,
          price: eventCartItm.priceId,
        })
      }
    })

      if (auth.currentUser == null){
        const ua = window.navigator.userAgent.toLowerCase();
        const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
        auth.signInAnonymously().then(()=>{
          const authUID = auth.currentUser.uid;
          fires
          .collection('users')
          .doc(authUID)
          .set({
            userName: firstName + " " + lastName,
            name: firstName + " " + lastName,
            firstName: firstName,
            lastName: lastName,
            work: '',
            wechat: '',
            email: userEmail,
            lang: i18n.language,
            currentEvent: id,
            forms: formAnswers,
            cart: eventCart,
            role: 'user',
            viewId: currentViewId,
            ua: ua,
            timestamp: timestampNow,
          }).then(()=>{
            if (userEmail != ""){
              const usersName = firstName + " " + lastName
              const dateSelected = selectedDay

              if (formAnswers.length > 0){
                fires
                .collection('events')
                .doc(id)
                .collection('formSubmissions')
                .add({
                  forms: formAnswers,
                  email: userEmail,
                  uid: authUID,
                  timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
                })
              }
              payShoppingCartTourTicket(
                authUID,
                id,
                eventDetails.title,
                calculateTax,
                usersName,
                firstName,
                lastName,
                eventCart,
                paymentItems,
                userEmail,
                dateSelected,
                ua,
                orgDetails.id,
                applyCoupon,
                uniqueUUID)
            }
          }).catch((errorr) => {
          })
        })
        .catch((error) => {
        });
      }
      else{
        const uid = auth.currentUser.uid
        const ua = window.navigator.userAgent.toLowerCase();
        const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
        const usersName = firstName + " " + lastName
        const dateSelected = ""
        if (uid != ""){
          if (userEmail != ""){
            fires.collection('users').doc(uid).update({
              cart: eventCart, 
            })
            if (formAnswers.length > 0){
              fires
              .collection('events')
              .doc(id)
              .collection('formSubmissions')
              .add({
                forms: formAnswers,
                email: userEmail,
                uid: uid,
                timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
              })
            }
            payShoppingCartTourTicket(
              uid,
              id,
              eventDetails.title,
              calculateTax,usersName,
              firstName,
              lastName,
              eventCart,
              paymentItems,
              userEmail,
              dateSelected,
              ua,
              orgDetails.id,
              applyCoupon,
              uniqueUUID)
            
          }

          
        }
        else{
          window.alert("Your email is invalidate, please try again")
        }
      }

      
    }

    


    function handleEventFreeRegistration(userEmail,firstName,lastName,quantity){
      //process rules
      var formAnswers = []
      eventAdditionalQuestions.forEach((eventQItm) => {
        formAnswers.push(
          {
          title: eventQItm.title, 
          value: eventQItm.value,
          id: eventQItm.id,
        })
      })
      let dateStr = ""
      let promoC = ""
      let priceDesc = eventPriceOptions.priceDescription
      if (selectedDates.length > 0){
          dateStr = selectedDates[0]
          priceDesc += ` (${dateStr})`
      }
      if (formAnswers.length == 0){
        eventRegistrationWithoutForm(
          userEmail,
          firstName,
          lastName,
          quantity,
          id,
          priceDesc,
          dateStr,
          promoC)
      }
      else{
        eventRegistrationWithForm(
          userEmail,
          firstName,
          lastName,
          quantity,
          id,
          formAnswers,
          priceDesc,
          dateStr,
          promoC)
      }

    }


    const [notFilledIds, setNotFilledIds] = useState([])
    async function handleUserRegister(e){
        e.preventDefault()
        const uFirstName = eventRegisterFirstNameRef.current.value;
        const uLastName = eventRegisterLastNameRef.current.value;
        const uEmail = eventRegisterEmailRef.current.value.toLowerCase();
        const uConfirmEmail = eventRegisterEmailConfirmRef.current.value.toLowerCase();
        setError("");
        setLoading(true);

        //check missing value
        var notfilledValue = []
        var notfilledIds = []
        eventAdditionalQuestions.forEach((quesItm, index) => {
          if (quesItm.required && quesItm.value == ""){
            notfilledValue.push(`${index+1}. ` + quesItm.title);
            notfilledIds.push(quesItm.id);
          }
        })
        

        var multiDayEvent = eventOptions.multipleDay
        var cartEmpty = true

        //calculate the shopping cart
        if (Object.values(shoppingCart).length > 0) {
          //
          cartEmpty = false
          setEventCart(Object.values(shoppingCart))
        }
        

        if (!eventDetails.ticketStatus) {
          //ticket not available
          window.alert("Ticket is not available to purchase")
          setLoading(false)
        }
        else if (multiDayEvent && selectedDates.length == 0){
          window.alert("Please select days first")
          setLoading(false)
        }
        else if (cartEmpty){
          //empty cart
          window.alert("Please add items to cart")
          setLoading(false)
        }
        else if (notfilledValue.length > 0){
          //required field not filled
          setNotFilledIds(notfilledIds)
          let allValues = notfilledValue.join('\n')
          window.alert("Please fill in required fields, \n" + allValues)
          setLoading(false)
        }
        else{
          if (multiDayEvent){
            eventAdditionalQuestions.push({id: 'date-selector',title: 'Selected Day',value: selectedDates.join(','),valueType: 'options',optionType: ''})
          }
          if (uEmail != "" && uEmail == uConfirmEmail && uEmail.includes("@") && uEmail.includes(".")){
            
            //credit card payment
            let taxCalculate = false
            if (eventPriceOptions.priceIncludeTax != null){
              if (eventPriceOptions.priceIncludeTax){
                taxCalculate = eventPriceOptions.priceIncludeTax
              }
            }
            handleEventCreditCardPayment(uEmail,uFirstName,uLastName,taxCalculate)
          }
          else{
            setLoading(false)
            if (uEmail == ''){
              window.alert("You have to enter valid email")
            }
            if (uEmail != uConfirmEmail){
              window.alert("Your emails are not matched, please make sure you entered valid email address.")
            }
            if (!uEmail.includes('@') || !uEmail.includes('.')){
              window.alert('Your email is not in correct format')
            }
            
          }
        }
        
        
      }


      function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true)
  }
    
    return (false)
}

function loadFormQuestions(repeatNum) {
  setEventAdditionalQuestions([])
  fires
    .collection('events')
    .doc(id)
    .collection('forms')
    .get()
    .then((snapshotVal)=>{
      var formDataSet = []
      if (repeatNum > 1){
        for (let i = 0; i < repeatNum; i++){
          snapshotVal.docs.forEach((itm) => {
            const formId = itm.id
            const formData = itm.data()
            formDataSet.push({...formData, id: formId, value: ''})
          })
        }
        formDataSet.sort((a,b) => {
          return a.order - b.order
        })
      }
      else{
        snapshotVal.docs.forEach((itm) => {
          const formId = itm.id
          const formData = itm.data()
          formDataSet.push({...formData, id: formId, value: ''})
        })
        formDataSet.sort((a,b) => {
          return a.order - b.order
        })
      }
      setEventAdditionalQuestions(formDataSet)

    })
}

function evaluatePricingRules(priceId, rules, selectedQuantity){
  let pricingRules = []
  let quan = 0
  let evaluationResult = true
  let message = ""
  let clearCartAction = false

  if (rules != null){
    pricingRules = rules
  }
  if (selectedQuantity != null){
    quan = selectedQuantity
  }

  let field_ids = []

  let ruleResults = []
  let messages = []
  pricingRules.forEach((ruleSet) => {
    //$type: add-on AND $price_id(aaaa).quan > 2 AND $price_id(dadaw).quan > 2
    if (ruleSet.priority != null){}
    if (ruleSet.clearCart != null){
      clearCartAction = ruleSet.clearCart
    }
    if (ruleSet.message != null){
      message = ruleSet.message
    }
    if (ruleSet.conditions != null){
      let rulePass = false
      ruleSet.conditions.forEach((condition) => {
        let conditionS = {
          destination: "",
          value: "",
          valueOptions: "",
          operator: "",
          field: "",
          field_id: ""
        }
        conditionS = condition
        //condition price in cart
        //operators IN, NOT IN, >, >=, <, <=, ==
        if (conditionS.operator == "IN" && 
        conditionS.field == "price" && 
        conditionS.field_id != "" && 
        conditionS.destination == "cart"){
          field_ids.push(conditionS.field_id)
          rulePass = Object.keys(shoppingCart).includes(conditionS.field_id)
        }
        else if (conditionS.operator == "NOT IN"){
          rulePass = true
        }
        else if (conditionS.operator == "=="){
          rulePass = true
        }
        else if (conditionS.operator == ">="){
          rulePass = true
        }
        else if (conditionS.operator == "<="){
          rulePass = true
        }
        else if (conditionS.operator == "<"){
          rulePass = true
        }
        else if (conditionS.operator == ">"){
          rulePass = true
        }
        else if (conditionS.operator == "ARRAY CONTAINS"){
          rulePass = true
        }
        else if (conditionS.operator == "ARRAY NOT CONTAINS"){
          rulePass = true
        }
        else {
          rulePass = true
        }
      })
      ruleResults.push(rulePass)
      if (!rulePass){
        messages.push(message)
      }
    }
    
  })

  if (ruleResults.includes(false)){
    evaluationResult = false
    //setShoppingCartRuleAppliedPriceIds(field_ids)
  }
  else{
    evaluationResult = true
  }

  return {result: evaluationResult, message: messages.join('\n'), clearCart: clearCartAction}
}


    
    useEffect(() => {
        fires
        .collection('events')
        .doc(id)
        .get()
        .then((snapshot) => {
            if (snapshot.exists) {
                const postData = snapshot.data()

                document.title = postData.title;
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", postData.description);

              var ua = window.navigator.userAgent.toLowerCase();

          const timestampNow = firebase.firestore.Timestamp.fromDate(
            new Date()
          );
          const platformName = "web";
          let deviceName = "";
          if (isAndroid) {
            deviceName = "android";
          } else if (isIOS) {
            deviceName = "ios";
          } else {
            deviceName = "web";
          }
          
          var viewLang = i18n.language
          const viewData = {
            fromUA: ua,
            fromUID: '',
            platform: platformName,
            device: deviceName,
            lang: viewLang,
            url: window.location.href,
            timestamp: timestampNow,
          };
          
          const vId = uuid();
          setCurrentViewId(vId);

          fires
          .collection("events")
          .doc(id)
          .collection("views")
          .doc(vId)
          .set(viewData);

          
          if (postData.exhibitions != null){
            setExhibitions(postData.exhibitions)
          }

            if (postData.photoURL !== "") {
              let link = document.querySelector("link[rel~='icon']");
              if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.getElementsByTagName("head")[0].appendChild(link);
              }
              link.href = postData.photoURL;
            }
            
                if (lang != null){
                  i18n.changeLanguage(lang)
                }
                if (postData.status != null) {
                  if (postData.status == 'live'){
                    
                    if (postData.priceType != null){
                        if (postData.priceType == "CREDIT" || postData.priceType == "EMT" || postData.priceType == "FREE"){

                          var ticketS = false
                          if (postData.ticketStatus != null){
                            ticketS = postData.ticketStatus
                          }
                          else{
                            ticketS = true
                          }
                          var zhDescription = []
                          var enDescription = []
                          if (postData.descriptions != null) {
                            if (postData.descriptions.en != null){
                              enDescription = postData.descriptions.en
                            }
                            if (postData.descriptions.zh != null){
                              zhDescription = postData.descriptions.zh
                            }
                          }

                          let refundPolicy = "No Refund"
                          if (postData.refundPolicy != null){
                            refundPolicy = postData.refundPolicy
                          }

                          let dateRange = getDateRange(postData.startDate.toDate(),postData.endDate.toDate())
                          setPickDateRange(dateRange)
                          
                          setEventDetails({
                            ...postData, 
                            startDate: postData.startDate, 
                            endDate: postData.endDate, 
                            ticketStatus: ticketS, 
                            refundPolicy: refundPolicy,
                            descriptions: {en: enDescription, zh: zhDescription}})
                          
                          
                            
                            
                        }
                        else{
                            routeBackEvents(id)
                        }
                    }
                    else{
                        routeBackEvents(id)
                    }

                    var fuid = ''
                    var femail = ''
                    const orgId = postData.orgId

                    loadFormQuestions(1)

                    //options
                    if (postData.options != null){
                      setEventOptions(postData.options)
                      var maxT = 500
                      if (postData.options.max != null){
                        maxT = postData.options.max
                        checkCurrentTickets(maxT)
                      }
                    }
                    
                    if (postData.prices != null){
                      var pricesAll = []
                      Object.keys(postData.prices).forEach((key) => {
                        const priceItm = postData.prices[key]
                        let min = 0
                        if (priceItm.min != null){
                          min = priceItm.min
                        }
                        if (priceItm.saleStart != null && priceItm.saleEnd != null){
                          let saleStart = firebase.firestore.Timestamp.fromDate(new Date())
                          let saleEnd = firebase.firestore.Timestamp.fromDate(new Date())
                          let cDate = new Date()
                          saleStart = priceItm.saleStart
                          saleEnd = priceItm.saleEnd
                        }
                        let priceRules = []
                        let field_ids = []
                        if (priceItm.priceRules != null){
                          priceRules = priceItm.priceRules
                          //fill out field ids
                          priceRules.forEach((ruleItm) => {
                            const rconditions = ruleItm.conditions
                            if (rconditions != null){
                              rconditions.forEach((conditionA) => {
                                if (conditionA.field_id != null && !field_ids.includes(conditionA.field_id)){
                                  field_ids.push(conditionA.field_id)
                                }
                              })
                            }
                          })

                          //use field id
                          setShoppingCartRuleAppliedPriceIds(field_ids)

                        }
                        pricesAll.push({
                          ...priceItm, 
                          id: priceItm.id, 
                          quantity: min, 
                          priceRules: priceRules})
                      })
                      pricesAll.sort((priceA,priceB) => {
                        return priceA.order - priceB.order
                      })
                      
                      //filter tickets that's not in range
                      pricesAll = pricesAll.filter((priceA) => {
                        let saleStart = firebase.firestore.Timestamp.fromDate(new Date())
                        let saleEnd = firebase.firestore.Timestamp.fromDate(new Date())
                        let saleNow = firebase.firestore.Timestamp.fromDate(new Date())
                        if (priceA.saleStart != null){
                          saleStart = priceA.saleStart
                        }
                        if (priceA.saleEnd != null){
                          saleEnd = priceA.saleEnd
                        }
                        if (saleNow >= saleStart && saleNow < saleEnd){
                          return true
                        }
                        else{
                          return false
                        }
                      })

                      setEventPrices(pricesAll)
                    }
                    else{
                      

                      var priceDesc = ""
                      if (postData.priceDescription != null){
                        priceDesc = postData.priceDescription
                      }
                      else{
                        priceDesc = i18n.language == 'en' ? 'General Admission' : '通用票'
                      }
                      let ticketMax = 10
                      let ticketMin = 1
                      let priceTax = 0
                      if (postData.ticketMax != null){
                        ticketMax = postData.ticketMax
                      }
                      if (postData.ticketMin != null){
                        ticketMin = postData.ticketMin
                      }
                      if (postData.priceTax != null){
                        priceTax = postData.priceTax
                      }
                      setEventPriceOptions({
                        quan: 1,
                        price: postData.price, 
                        priceTax: priceTax,
                        priceCurrency: postData.priceCurrency, 
                        priceEMTEmail: postData.priceEMTEmail, 
                        priceId: postData.priceId, 
                        priceOriginal: postData.priceOriginal, 
                        id: 'default-ticket',
                        priceDescription: priceDesc,
                        priceType: postData.priceType,
                        startDate: postData.startDate,
                        endDate: postData.endDate,
                        max: ticketMax,
                        min: ticketMin,
                        priceIncludeTax: false,
                        remaining: availableTickets,
                        saleStart: firebase.firestore.Timestamp.fromDate(new Date()),
                        saleEnd: postData.startDate})
                      setEventTicketSelected(true)
                    }

                    

                    if (auth.currentUser != null) {
                        fuid = auth.currentUser.uid
                        currentUID = fuid
                        
                        if (fuid != ''){
                            fires
                            .collection('users')
                            .doc(fuid)
                            .get().then((snapshotA)=>{
                                const snapData = snapshotA.data()
                                setUserDetails({...snapData, id: fuid})
                                if (snapData.email != null){
                                  let fFirstN = ""
                                  let fLastN = ""
                                  if (snapData.firstName != null){
                                    fFirstN = snapData.firstName
                                  }
                                  if (snapData.lastName != null){
                                    fLastN = snapData.lastName
                                  }
                                  femail = snapData.email

                                  eventRegisterFirstNameRef.current.value = fFirstN
                                  eventRegisterLastNameRef.current.value = fLastN
                                  eventRegisterEmailRef.current.value = femail
                                  eventRegisterEmailConfirmRef.current.value = femail
                                }
                            })
                            if (orgId != ""){
                                fires.collection('groups').doc(orgId).get().then((snapshotA)=>{
                                    const snapData = snapshotA.data()
                                    setOrgDetails({...snapData, id: orgId})
                                    if (snapData.profileURL != ""){
                                        setProfileImg(snapData.profileURL)
                                    }
                                    if (snapData.coverURL != ""){
                                        setCoverImg(snapData.coverURL)
                                    }
                                    
                                })
                            }
                        }
                        else{
                            routeRedirect404()
                        }
                        
                    }
                    else{
                        //no auth
                        if (orgId != ""){
                          fires.collection('groups').doc(orgId).get().then((snapshotA)=>{
                              const snapData = snapshotA.data()
                              setOrgDetails({...snapData})
                              if (snapData.profileURL != ""){
                                  setProfileImg(snapData.profileURL)
                              }
                              if (snapData.coverURL != ""){
                                  setCoverImg(snapData.coverURL)
                              }
                          })
                      }
                    }
                  }
                  else{
                    //not live, or ended
                    routeRedirect404()
                  }
                }
                else{
                  //not found
                  //deleted
                  routeRedirect404()
                }

            }
            else{
                //deleted
                routeRedirect404()
                
            }
        });
      }, []);


    

      function getDateRange(startDate, stopDate) {

        const cFrom = new Date(startDate);
        const cTo = new Date(stopDate);

        let daysArr = [cFrom.toLocaleDateString('en')];
        let tempDate = cFrom;

        while (tempDate < cTo) {
          tempDate.setUTCDate(tempDate.getUTCDate() + 1);
          const newD = new Date(tempDate);
          const newDString = newD.toLocaleDateString('en')
          daysArr.push(newDString);
        }
        return daysArr;

    }

  return (
    <>

<Helmet>
                <title>{eventDetails.title}</title>
                <meta
                  name="description"
                  content={eventDetails.description}
                />
                <meta name="twitter:card" content={eventDetails.photoURL} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={eventDetails.title} />
                <meta
                  name="twitter:description"
                  content={eventDetails.description}
                />
                <meta name="twitter:image" content={eventDetails.photoURL} />
                <meta property="og:title" content={eventDetails.title} />
                <meta
                  property="og:description"
                  content={eventDetails.description}
                />
                <meta property="og:image" content={eventDetails.photoURL} />
                <meta
                  property="og:url"
                  content={"https://evtgo.com/" + id}
                />
                <meta
                  property="og:site_name"
                  content={eventDetails.title}
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>

      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh",minWidth: '100vw', backgroundImage: 'url(' + coverImg + ')' }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0 align-items-center justify-content-center" 
            style={{
                alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor:"white",
            borderRadius: '0px',
            maxWidth: '100%',
            width: '100%',
            marginTop: '25px',
            marginBottom: '25px'}}
          >
            <div className="w-100 align-items-start justify-content-between" style={{marginTop: '15px', marginLeft: '15px', marginRight: '15px'}}>
              <Button className="border-0" href={i18n.language == 'en' ? `/${id}/en` : `/${id}/zh`} style={{backgroundColor: 'transparent', color: 'black'}}>{i18n.language == 'en' ? "← Back" : "← 返回活动"}</Button>
            </div>

            {
              uploadPicModal()
            }
            {
              applyCouponModal()
            }
            
              <Card.Body className = "align-items-center justify-content-center">
                
              <Card.Title className="text-center"
               style={{ fontSize: isMobile ? "22px" : "30px",
                fontWeight:'bold', 
                color: "#000000"}}>{eventDetails.title}</Card.Title>

            {error && <Alert variant="danger">{error}</Alert>}
             {success && <Alert variant="success">{success}</Alert>}

              <ListGroup style={{marginTop: '15px'}}>
                <ListGroup.Item className="d-flex align-items-start justify-content-between">
                  <div className="fw-normal">Exhibitions</div>
                  <div className="fw-bold">{exhibitionSelectedNames.join(" + ")}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex align-items-start justify-content-between">
                  <div className="fw-normal">Date</div>
                  <div className="fw-bold">{selectedDay}</div>
                </ListGroup.Item>
              </ListGroup>
              <div style={{marginTop: '10px'}}>
                <Button 
                  variant="outline-danger" 
                  hidden={selectStep < 2}
                  onClick={()=>{
                    window.location.reload()
                  }}>Re-Select</Button>
              </div>

              {/* <Card.Text className="text-center"
               style={{ fontSize: isMobile ? "22px" : "30px",
                fontWeight:'normal', 
                color: "#000000"}}>{"Exhibitions: " + exhibitionSelectedNames.join(" ")}</Card.Text>

              <Card.Text className="text-center"
               style={{ fontSize: isMobile ? "22px" : "30px",
                fontWeight:'normal', 
                color: "#000000"}}>{selectedDay == "" ? "Select Date" : selectedDay}</Card.Text> */}


             {selectStep == 0 && selectExhibitionSection()}
             {selectStep == 1 && selectDateSection()}
             {selectStep == 2 && selectTicketsSelection()}
             

                  <Card hidden={userDetails.email == ""} style={{marginTop: '15px'}}>
                    <Card.Body>
                      <Card.Text>{i18n.language == 'en' ? 'Logged in as ' : '使用该邮箱买票 '}{userDetails.email}</Card.Text>
                      <Card.Text>{i18n.language == 'en' ? 'Email: ' : '您的电子邮箱：'}<b>{userDetails.email}</b></Card.Text>
                      <Card.Text>{i18n.language == 'en' ? 'First Name: ' : '您的名字：'}<b>{userDetails.firstName}</b></Card.Text>
                      <Card.Text>{i18n.language == 'en' ? 'Last Name: ' : '您的贵姓：'}<b>{userDetails.lastName}</b></Card.Text>
                      <div>
                        <Button variant="dark" onClick={()=>{
                          //logout
                          if (currentUser != null){
                            logout()
                            window.location.reload()
                          }
                        }}>{i18n.language == 'en' ? 'Not you? Checkout as guest' : '不是本人？重新填写信息'}</Button>
                      </div>
                    </Card.Body>
                  </Card>

                    <Form style={{marginTop: '25px'}} hidden={userDetails.email != ""}>
                    <Form.Group className="mb-3" controlId="register.lastn">
                    <FloatingLabel
                    label={t("event_details_registration_header_signup_firstname") + " *"}>
                  <Form.Control
                          type="text"
                          placeholder=""
                          defaultValue=""
                          ref={eventRegisterFirstNameRef}
                          autoFocus
                          required
                        />
                  </FloatingLabel>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="register.firstn">
                      <FloatingLabel
                    label={t("event_details_registration_header_signup_lastname") + " *"}>
                  <Form.Control
                          type="text"
                          placeholder=""
                          defaultValue=""
                          ref={eventRegisterLastNameRef}
                          required
                        />
                  </FloatingLabel>
                        
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="register.email">
                        <FloatingLabel
                    label={t("event_details_registration_header_signup_email") + " *"}>
                        <Form.Control
                          type="email"
                          placeholder=""
                          ref={eventRegisterEmailRef}
                          onChange={(e)=>{
                            const emailEntered = e.target.value
                            if (validate(emailEntered)){
                              console.log('valid email: '+emailEntered)
                            }
                            else{
                              console.log('not valid email: '+emailEntered)
                            }
                          }}
                          required
                        />
                    </FloatingLabel>

                      </Form.Group>
                                                        
                                                        <Form.Group className="mb-3" controlId="register.repeat.email">
                                                          <FloatingLabel
                                                      label={t("event_details_registration_header_signup_repeat_email") + " *"}>
                                                          <Form.Control
                                                            type="email"
                                                            placeholder=""
                                                            ref={eventRegisterEmailConfirmRef}
                                                            onChange={(e)=>{
                                                              const emailEntered = e.target.value
                                                              if (validate(emailEntered)){
                                                                console.log('valid email: '+emailEntered)
                                                              }
                                                              else{
                                                                console.log('not valid email: '+emailEntered)
                                                              }
                                                            }}
                                                            required
                                                          />
                                                      </FloatingLabel>

                                                        </Form.Group>

                                                        

                      
                      
                      

                      </Form>

                      <Card className="d-flex justify-content-center" style={{ marginTop: "25px", marginBottom: "25px"}} hidden={eventAdditionalQuestions.length == 0}>
                        <Card.Title style={{ fontSize: "18px",
                fontWeight:'bold',
                marginTop: '15px',
                marginLeft: '15px', 
                color: "#000000"}}
                hidden={eventAdditionalQuestions.length == 0}>{t("event_registration_more_questions")}</Card.Title>

                        <Form hidden={eventAdditionalQuestions.length == 0}>
                      {
                        eventAdditionalQuestions.map((formItm) => {
                          const orderI = formItm.order
                          const moreOptons = formItm.optionMore
                          const defaultVal = formItm.value
                          let allowMultiple = false
                          let styles = {
                            color: notFilledIds.includes(formItm.id) ? 'red' : 'black'
                          }
                            
                            if (formItm.allowMultiple != null){
                              allowMultiple = formItm.allowMultiple
                            }
                            if (formItm.styles != null){
                              styles = formItm.styles
                            }

                          if (formItm.optionType == 'options'){
                            
                            return <Form.Group style={{
                              marginTop: '15px',
                              marginBottom: '10px',
                            marginLeft: '15px',
                            marginRight: '15px'}}>
                                      <Form.Label hidden={formItm.options.length != 0} style={styles}>{formItm.title}</Form.Label>
                                      <Form.Label hidden={formItm.options.length == 0} style={styles}>{formItm.title} {formItm.required ? '*' : ''}</Form.Label>
                                      <Form.Label><b>{formItm.value == '' ? '' : ' - ' + formItm.value}</b></Form.Label>
                                  {
                                    formItm.options.map((optionItm) => {
                                      return <Form.Check 
                                      type={'checkbox'}
                                      id={optionItm}
                                      label={optionItm}
                                      checked={allowMultiple ? defaultVal.includes(optionItm) : defaultVal == optionItm}
                                      onChange={(e)=>{
                                        let checked = e.target.checked
                                        if (allowMultiple){
                                          let newSelections = ""
                                          if (checked){
                                            //adding
                                            var eventQues = eventAdditionalQuestions
                                            const currentSelections = eventQues[orderI].value
                                            if (currentSelections == ""){
                                              newSelections = optionItm
                                            }
                                            else {
                                            if (currentSelections.includes(' | ')){
                                              let allComponents = []
                                              allComponents = currentSelections.split(' | ')
                                              allComponents.push(optionItm)
                                              newSelections = allComponents.join(' | ')
                                            }
                                            else{
                                              newSelections = currentSelections + ' | ' + optionItm
                                            }
                                            }

                                          }
                                          else{
                                            //remove
                                            var eventQues = eventAdditionalQuestions
                                            const currentSelections = eventQues[orderI].value
                                            if (currentSelections != ""){
                                              if (currentSelections.includes(' | ')){
                                                let allComponents = []
                                                allComponents = currentSelections.split(' | ')
                                                if (allComponents.length > 0){
                                                  let cIndex = allComponents.indexOf(optionItm)
                                                  allComponents.splice(cIndex,1)
                                                }
                                                newSelections = allComponents.join(' | ')
                                              }
                                              else{
                                                newSelections = ""
                                              }
                                            }
                                          }
                                          
                                          eventQues[orderI].value = newSelections
                                          setEventAdditionalQuestions(eventQues)
                                          setSuccess(formItm.title + ' - ' + newSelections )
                                        }
                                        else{
                                          var eventQues = eventAdditionalQuestions
                                          eventQues[orderI].value = optionItm
                                          setEventAdditionalQuestions(eventQues)
                                          setSuccess(formItm.title + ' - ' + optionItm)
                                        }

                                        

                                      }}
                                    />
                                    })
                                  }

                          </Form.Group>
                          }
                          else if (formItm.optionType == 'text'){
                            if (formItm.valueType == 'phone') {
                              return <Form.Group 
                                className="mb-3" 
                                style={{
                                marginTop: '15px',
                                marginBottom: '10px',
                              marginLeft: '15px',
                              marginRight: '15px'}}>
                                <Form.Label style={styles}>{formItm.title + (formItm.required ? ' *' : ' ')}</Form.Label>
                                <PhoneInput
                                  placeholder={formItm.title}
                                  value={formItm.value}
                                  defaultCountry="CA"
                                  onChange={(e) => {
                                    var eventQues = eventAdditionalQuestions
                                    eventQues[orderI].value = e
                                    setEventAdditionalQuestions(eventQues)
                                  }}/>

                              </Form.Group>
                            }
                            else if (formItm.valueType == 'address'){
                              let placeAddr = ""
                              return <Form.Group 
                              className="mb-3" 
                              style={{
                              marginTop: '15px',
                              marginBottom: '10px',
                            marginLeft: '15px',
                            marginRight: '15px'}}>
                              <Form.Label style={styles}>{formItm.title + (formItm.required ? ' *' : ' ')}</Form.Label>
                              <br/>
                              <Autocomplete
                                style={{width: '100%',}}
                                placeholder={'Type to select address'}
                                apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
                                options={{
                                  types: [],
                                  lang: 'en',
                                  componentRestrictions: { country: "ca" },
                                }}
                                onPlaceSelected={(place) => {
                                  const placeAddress = place.formatted_address
                                  var eventQues = eventAdditionalQuestions
                                  eventQues[orderI].value = placeAddress
                                  placeAddr = placeAddress
                                  setEventAdditionalQuestions(eventQues)
                                  setSuccess('Address updated ' + placeAddr)
                                }}
                              />
                              <Form.Label style={{color: 'black', marginTop: '5px'}} hidden={eventAdditionalQuestions[orderI].value == ""}>✓ Address selected: {eventAdditionalQuestions[orderI].value}</Form.Label>
                            </Form.Group>
                            }
                            else{
                              return <Form.Group className="mb-3" controlId="register.email" style={{
                                marginTop: '15px',
                                marginBottom: '10px',
                              marginLeft: '15px',
                              marginRight: '15px'}}>
                                <Form.Label style={styles}>{formItm.title + (formItm.required ? ' *' : ' ')}</Form.Label>
                                <Form.Control
                                type={formItm.valueType}
                                placeholder={formItm.placeholder}
                                defaultValue=""
                                required={formItm.required}
                                onChange={(e)=>{
                                  const enteredValue = e.target.value
                                  var eventQues = eventAdditionalQuestions
                                  eventQues[orderI].value = enteredValue
                                  setEventAdditionalQuestions(eventQues)
                                }}
                              />
                            </Form.Group>
                            }
                            
                          }
                          else if (formItm.optionType == 'textarea'){
                            return <Form.Group className="mb-3" controlId="register.email" style={{
                              marginTop: '15px',
                            marginBottom: '10px',
                          marginLeft: '15px',
                          marginRight: '15px'}}>
                            <Form.Label style={styles}>{formItm.title + (formItm.required ? ' *' : ' ')}</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={5}
                              placeholder={formItm.placeholder}
                              defaultValue=""
                              required={formItm.required}
                              onChange={(e)=>{
                                const enteredValue = e.target.value
                                var eventQues = eventAdditionalQuestions
                                eventQues[orderI].value = enteredValue
                                setEventAdditionalQuestions(eventQues)
                              }}
                            />
                          </Form.Group>
                          }
                          else if (formItm.optionType == 'file'){
                            return <Form.Group
                            className="mb-3" 
                                style={{
                                marginTop: '15px',
                                marginBottom: '10px',
                              marginLeft: '15px',
                              marginRight: '15px'}}>
                                <Form.Label>{formItm.title + (formItm.required ? ' *' : ' ')}</Form.Label>
                                <Form.Label>{formItm.placeholder}</Form.Label><br/>
                                <Button variant="dark" onClick={()=>{
                                  setFileUploadindex(formItm.order)
                                  handleUploadShow()
                                }}>Upload</Button>
                            </Form.Group>
                            
                          }
                          else{

                          }
                        })
                      }
                      </Form>
                        
                      </Card>
                      <Form style={{marginTop: '25px'}}>
                      <Form.Group className="align-items-start" controlId="price-item" style={{marginTop: '15px'}}>
                      <Card.Title style={{ fontSize: "18px",
                                          fontWeight:'bold',
                                          marginTop: '15px', 
                                          marginBottom: '15px',
                                          color: "#000000"}}>{`My Cart (${eventCart.length})`}</Card.Title>
                        <ListGroup>
                        {
                          eventCart.map((cartItm, order) => {
                            return <ListGroup.Item >
                              <div className="d-flex align-items-center justify-content-between">
                                  <div className="fw-normal">{cartItm.priceDescription}</div>
                                  <div className="fw-bold">{cartItm.price.toLocaleString('en-US', {style: 'currency', currency: cartItm.priceCurrency})} * {cartItm.quantity}</div>
                              </div>
                              <div>
                                <Card.Link style={{marginTop: '10px', color: 'red'}} onClick={()=>{
                                  if (shoppingCartRuleAppliedPriceIds.includes(cartItm.id)){
                                    //remove all
                                    let restrictionW = ""
                                    if (i18n.language == 'en'){
                                      restrictionW = 'The item you are trying to remove has restrictions, we will remove all items from your cart.'
                                    }
                                    else{
                                      restrictionW = '您想要移除的商品有限定条件，如果移除将会清空整个购物车'
                                    }
                                    const promptConfirm = window.confirm(restrictionW)
                                    if (promptConfirm){
                                      setShoppingCart({})
                                      setEventCart([])
                                      calculateCartAmount()
                                      const uuidd = uuid()
                                      setSuccess('Cart removed: ' + uuidd)
                                    }
                                  }
                                  else{
                                    const promptConfirm = window.confirm('Do you want to remove ' + cartItm.priceDescription + '?')
                                  if (promptConfirm){
                                  if (Object.keys(shoppingCart).includes(cartItm.id)){
                                    //cart include
                                    let sCart = shoppingCart
                                    delete sCart[cartItm.id]
                                    setShoppingCart(sCart)
                                    setEventCart(Object.values(shoppingCart))
                                    calculateCartAmount()
                                    setSuccess('Cart removed: ' + uuid())
                                  }
                                  else{
                                    setSuccess('Cart not changed ' + uuid())
                                  }
                                  
                                }

                                  }
                                }}>Remove</Card.Link>
                              </div>
                        </ListGroup.Item>
                          })
                        }
                        </ListGroup>
                        
                        
                        <div className="d-flex align-items-center justify-content-between" style={{marginTop: '25px'}} hidden={eventPriceOptions.priceTax == 0.0}>
                            <div className="fw-normal" hidden={eventPriceOptions.priceTax == 0 || eventPriceOptions.priceTax == null}>{t('event_details_registration_header_tax_price')}</div>
                            <div className="fw-bold" hidden={eventPriceOptions.priceTax == 0 || eventPriceOptions.priceTax == null}>Tax Included</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-bold">{'Total Amount'}</div>
                            <div className="fw-bold">{(Math.round(totalAmount * 100) / 100).toLocaleString('en-US',{ style: 'currency', currency: 'CAD'})}</div>
                        </div>
                    </Form.Group>
                    <Form.Group className="align-items-start" controlId="price-item" style={{marginTop: '15px'}}>
                        <div className="fw-bold">{t('event_details_heading_refund_info')}</div>
                        <div className="fw-normal">{eventDetails.refundPolicy}</div>
                    </Form.Group>


                      <Form.Group style={{width: '100%', marginTop: '20px'}} hidden={!eventOptions.coupon || true}>
                        <Card.Link 
                        style={{color: 'black', fontWeight: 'bold', fontSize: 20}} 
                        hidden={!eventOptions.coupon}
                        onClick={()=>{
                          if (eventPriceOptions.priceType != "FREE"){
                            setShowCoupon(true)
                          }
                        }}>{i18n.language == 'en' ? 'Apply coupon' : '使用优惠码'}</Card.Link>
                        <Card.Text 
                        hidden={!eventOptions.coupon}
                        style={{color: "black", fontWeight: 'bold'}}
                        >{userCoupon.coupon != "" ? (i18n.language == 'en' ? "Coupon applied: " + userCoupon.coupon : '已经使用优惠码: ' + userCoupon.coupon) : ""}</Card.Text>
                      </Form.Group>

                      <Form.Group 
                        className="mb-3" 
                        controlId="register.click.email"
                        style={{marginTop: '25px'}}>
                        <Form.Label style={{fontSize: 17, fontWeight: 'normal'}}>Your selected day: {selectedDay} {exhibitionSelectedNames.join("")}</Form.Label><br/>
                        <Form.Label style={{fontSize: 15, fontWeight: 'normal'}}>ROM hours are <b>10:00 am to 5:30 pm</b> on open days. Check <a href="https://rom.on.ca">rom.on.ca</a> for open days. The last entry for exhibitions is at <b>4PM</b>.</Form.Label>
                        <Form.Label style={{marginTop: '10px', fontSize: 20, fontWeight: 'bold'}}>Your tickets will be delivered to your email once it’s been issued. (Normally 8 hours within time of purchase)
Please allow maximum 24 hours for us to process the order during weekends or holidays.</Form.Label>
                      </Form.Group>

                      <Form.Group 
                        className="mb-3" 
                        controlId="register.click.email"
                        style={{marginTop: '25px'}}>
                        <Form.Label style={{fontSize: 20, fontWeight: 'bold'}}>Terms and Conditions</Form.Label><br/>
                        <Form.Label className="mt-1.5" style={{fontSize: 15, fontWeight: 'normal'}}>• This ticket will be scanned on the day of your visit for authenticity.</Form.Label>
                        <Form.Label className="mt-1.5" style={{fontSize: 15, fontWeight: 'normal'}}>• Each ticket can only be scanned and used once. In the event that duplicate copies appear, the Museum reserves the right to refuse entry to all ticket holders.</Form.Label>
                        <Form.Label className="mt-1.5" style={{fontSize: 15, fontWeight: 'normal'}}>• If applicable, student ID or other identification may be asked on the day of your visit.</Form.Label>
                        <Form.Label className="mt-1.5" style={{fontSize: 15, fontWeight: 'normal'}}>• All ticket sales are final. Tickets cannot be refunded or exchanged.</Form.Label>
                      </Form.Group>

                      <Form.Group 
                        className="mb-3" 
                        controlId="register.click.email"
                        style={{marginTop: '25px'}}>
                          <Form.Check 
                          type="checkbox"
                          defaultChecked={false}
                          onChange={(e)=>{
                            const eT = e.target.checked
                          }} label="Keep me updated on more events and news from this event organizer." />
                          <Form.Check 
                            type="checkbox" 
                            defaultChecked={false}
                            label="Send me emails about the best events happening nearby or online." />
                          <Form.Label style={{marginTop: '10px'}}>By selecting 'Buy Tickets', I agree to the <a href="https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/EventGo_Privacy_Policy.pdf">EventGo Terms of Services</a></Form.Label>
                      </Form.Group>
                      
                      <div 
                  className="d-flex align-items-center justify-content-center" 
                  id="form-register" style={{marginTop: '8px', marginBottom: '32px'}}>
                    <Button className="border-0" style={{width: '100%' ,height: '40px', fontWeight: 'bold', backgroundColor: "#f6AC19", color: 'black', borderRadius: '0px'}} 
                    disabled={!eventDetails.ticketStatus || loading ||
                                ((eventOptions.max - totalTicketCount <= 0) && eventOptions.max >= 0)}
                    onClick={(e)=>{
                      //actions register
                      handleUserRegister(e)
                      }}>
                        <Spinner animation="border" size="sm" hidden={!loading}/>{" "}
                      {'Buy Tickets'}
                      </Button>
                    </div>

                    </Form>
             
            </Card.Body>
          </Card>
        </div>
      </Container>
      
    </>
  );
}
