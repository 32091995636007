import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  FloatingLabel,
  Row,
  Col,
  ListGroup,
  Dropdown,
  DropdownButton,
  Navbar,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { auth, fires, storageRef, db } from "../firebase";
import { Link, useNavigate, Route, useParams } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import uuid from "react-uuid";
import Youtube from "react-youtube";
import firebase from "firebase/app";
import "firebase/firestore";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import {
  FaCalendarAlt,
  FaThumbsUp,
  FaBook,
  FaSearch,
  FaCommentDots,
  FaUserAlt,
  FaBell,
  FaBullhorn
} from "react-icons/fa";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


export default function UserDetails() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [index, setIndex] = useState(0);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [posts, setPosts] = useState([]);
  const [userData,setUserData] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    name: '',
    sponsor: false,
    sponsorId: '',
  })
  const [userOrg, setUserOrg] = useState({
    name: '',
    description: '',
    profileURL: '',
  })
  const [eventsAll, setEventsAll] = useState({})
  const [exploreEvents, setExploreEvents] = useState([])
  const [explorePosts, setExplorePosts] = useState([])
  const [exploreNotifications, setExploreNotifications] = useState([])
  const [exploreGroupChat, setExploreGroupChat] = useState([])
  const [exploreMyGroupChat, setExploreMyGroupChat] = useState([])
  const [userPosts, setUserPosts] = useState([])
  const [userGroups, setUserGroups] = useState([])
  const [postLoadNum, setPostLoadNum] = useState(50)

  const [paymentItems, setPaymentItems] = useState([])


  const [currentChatMessages, setCurrentChatMessages] = useState([])
  const [currentChatDetails, setCurrentChatDetails] = useState({
    roomTitle: '',
    users: {}
  })
  const [showCurrentChat, setShowCurrentChat] = useState(false)
  const messageChatRef = useRef()

  const [eventDetails, setEventDetails] = useState({
    title: '',
    description: '',
    titles: {en: '', zh: ''},
    descriptions: {en: [], zh: []},
    address: {fullAddress: ''},
    user: {userName: '', userOrg: ''},
    status: '',
    startDate: firebase.firestore.Timestamp.fromDate(new Date()),
    endDate: firebase.firestore.Timestamp.fromDate(new Date())
  });
  const [eventExist, setEventExist] = useState(false)
  const [userEventIds, setUserEventIds] = useState([])
  const [userEvents, setUserEvents] = useState([])

  const lngs = [
    { code: "en", native: "English" },
    { code: "zh", native: "简体中文" },
  ];

  const [profileImg, setProfileImg] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png")
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState(profileImg);
  const [uploadS, setUploadS] = useState("请点击上传图片开始上传")

  const [uploadedURL, setUploadedURL] = useState('')

  let { id } = useParams();
  const maxW = isMobile ? "100%" : "100%";

  const [showCreatePost, setShowCreatePost] = useState(false)
  const postContentRef = useRef()
  const [postImageSet, setPostImageSet] = useState([])
  const [selectedPostImage, setSelectedPostImage] = useState(null);
  const [selectedPostImageURL, setSelectedPostImageURL] = useState(profileImg);

  const [searchKeywords, setSearchKeywords] = useState("")
  const [searchData, setSearchData] = useState([])


  function searchFor(searchText){
    if (searchText != ""){
      var searchableData = []
      fires
      .collection('posts')
      .where('visibility','==','all')
      .get()
      .then((snapshot)=>{
        snapshot.docs.forEach((doc)=>{
          const docId = doc.id
          const docData = doc.data()
          if (docData.postContent != null){}
          if (docData.post != null){}
        })
      })
      fires
      .collection('events')
      .where('status','in',['live','private','paid-event'])
      .get().then((snapshot)=>{
        snapshot.docs.forEach((doc)=>{
          const docId = doc.id
          const docData = doc.data()
        })
      })
    }
  }

  async function handleInfoSubmit(e) {
    e.preventDefault();

    const emailA = e.target[0].value;
    const nameFU = e.target[1].value;
    const nameLU = e.target[2].value;
    const workU = e.target[3].value;
    const wechat = e.target[4].value;
    const profileUrl = uploadedURL
    setProfileImg(profileUrl)

    fires
      .collection("users")
      .doc(currentUser.uid)
      .update({
        email: emailA,
        firstName: nameFU,
        lastName: nameLU,
        userName: nameFU + ' ' + nameLU,
        work: workU,
        wechat: wechat,
        profileURL: profileUrl
      })
      .then(() => {
        const eventId = eventDetails.id;
        const userNames = nameFU + ' ' + nameLU
        if (currentUser.uid != ""){
          db.child('users').child(currentUser.uid).update({
            'userName': userNames,
            'profileURL': profileUrl,
            'coverURL': profileUrl,
            'timestamp': firebase.database.ServerValue.TIMESTAMP,
          })
        }

        if (eventId != "" && eventDetails.status != "ended") {
          fires
            .collection("events")
            .doc(eventId)
            .collection("members")
            .doc(currentUser.email)
            .update({ name: nameFU + ' ' + nameLU, firstName: nameFU, lastName: nameLU, work: workU, wechat: wechat, profileURL: profileUrl })
            .then(() => {
              
              setSuccess("You have successfully modified your info");
            });
          setSuccess("");
        }
      });
  }

  const handleImageChange = (e) => {
    e.preventDefault()
    const file = e.target.files[0];
    setSelectedImage(file)
  }

  const handlePostImageChange = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    setSelectedPostImage(file)
  }

  const handlePostImageSubmit = (e) => {
    e.preventDefault()
    const file = selectedPostImage;
    if (!file) return;
    uploadImage(file).then((downloadURL) => {
      setUploadS('uploaded')
      setSelectedPostImageURL(downloadURL)
      setSelectedPostImage(null)
      var uploadedPostPics = postImageSet
      uploadedPostPics.push(downloadURL)
      setPostImageSet(uploadedPostPics)
    })
    // var fileNameStr = Math.floor(Math.random() * (1000000 - 1000 + 1) + 1000)
    // var uploadTask = storageRef.child('expresources').child(auth.currentUser.uid + '_post_' + fileNameStr + '.jpg').put(file);
    // uploadTask.on('state_changed', 
    //   (snapshot) => {
    //     var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //     switch (snapshot.state) {
    //       case firebase.storage.TaskState.PAUSED: // or 'paused'
    //         setUploadS("Uploading stopped")
    //         break;
    //       case firebase.storage.TaskState.RUNNING: // or 'running'
    //       setUploadS('Uploading: ' + progress + '%')
    //         break;
    //     }
    // }, 
    // (error) => {
    //   setError("Uploading error")
    // }, 
    // () => {

    //   uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
    //     setSelectedPostImageURL(downloadURL)
    //     setSelectedPostImage(null)
    //     var uploadedPostPics = postImageSet
    //     uploadedPostPics.push(downloadURL)
    //     setPostImageSet(uploadedPostPics)
    // });

    // })
  }

  function handlePostCreateSubmit() {
    const postContent = postContentRef.current.value
    const postImages = postImageSet
    var postImage = ""
    if (postImages.length > 0){
      postImage = postImages[0]
    }
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    const userSet = posts[0]
    
    const postItm = {
      "fromUID":auth.currentUser.uid,
      "fromUserName":userSet.firstName + ' ' + userSet.lastName,
      "fromUserProfile": profileImg,
      "postContent":postContent,
      "imageURL":postImage,
      "voiceURL":"",
      "videoURL":"",
      "imageURLs":postImages,
      "postLocation":"",
      "visibility":"all",
      "recommended":"no",
      "orgId":"",
      "approved":"no",
      "topics":{},
      "postCoords":{},
      "likes": {},
      "youtubeId":"",
      "linkURL":"",
      "viewBy":[],
      "version":"web",
      "user": {
        "userName": userSet.firstName + ' ' + userSet.lastName,
        "profileURL": profileImg,
      },
      "timestamp":timestampNow,
      "modifiedAt":timestampNow
    }

    fires.collection('posts').add(postItm).then((postRef)=>{
      const postId = postRef.id
      setShowCreatePost(false)
      window.location.href = "/post/"+postId
    })


  }

  const handleImageSubmit = (e) => {
    e.preventDefault()
    const file = selectedImage;
    if (!file) return;
    var fileNameStr = Math.floor(Math.random() * (1000000 - 1000 + 1) + 1000)

    const imgURLFunction = uploadImage(file)
    imgURLFunction.then((fullURL) => {
        setSelectedImageURL(fullURL)
        setSelectedImage(null)
        setUploadedURL(fullURL)
    })

    // var uploadTask = storageRef.child('expresources').child(auth.currentUser.uid + '.jpg').put(file);

    // uploadTask.on('state_changed', 
    //   (snapshot) => {
    //     var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //     switch (snapshot.state) {
    //       case firebase.storage.TaskState.PAUSED: // or 'paused'
    //         setUploadS("上传已停止")
    //         break;
    //       case firebase.storage.TaskState.RUNNING: // or 'running'
    //       setUploadS('已上传: ' + progress + '%')
    //         break;
    //     }
    // }, 
    // (error) => {
    //   setError("上传错误")
    // }, 
    // () => {

    //   uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
    //     setSelectedImageURL(downloadURL)
    //     setSelectedImage(null)
    //     setUploadedURL(downloadURL)
    // });

    // })

    
  }

  function loadExplorePosts(loadNum){
    fires
    .collection('posts')
    .where('visibility','==','all')
    .orderBy('timestamp','desc')
    .limit(loadNum)
    .get()
    .then((snapshotA)=>{
      var expPosts = []
      snapshotA.docs.forEach((docA)=>{
        const docId = docA.id
        const docData = docA.data()
        if (docData.imageURL != ""){
          expPosts.push({...docData, id: docId})
        }
      })
      setExplorePosts(expPosts)
      
    })
  }

  function loadMyPosts(loadNum){
    var userUID = ""
    userUID = auth.currentUser.uid
    fires
    .collection('posts')
    .where('fromUID','==',userUID)
    .orderBy('timestamp','desc')
    .limit(loadNum)
    .get()
    .then((snapshotA)=>{
      var expPosts = []
      snapshotA.docs.forEach((docA)=>{
        const docId = docA.id
        const docData = docA.data()
        if (docData.imageURL != ""){
          expPosts.push({...docData, id: docId})
        }
      })
      setUserPosts(expPosts)
      
    })
  }

  function loadExploreEvents(){
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    fires
    .collection('events')
    .where('status','==','live')
    .where('startDate','>=',timestampNow)
    .orderBy('startDate','desc')
    .get()
    .then((snapshot) => {
        var allEvents = {}
        var expEvents = []
        snapshot.forEach((doc) => {
            const eventData = doc.data()
            var eveStartD = firebase.firestore.Timestamp.fromDate(new Date())
            var eveEndD = firebase.firestore.Timestamp.fromDate(new Date())
            if (eventData.startDate != null){
              eveStartD = eventData.startDate
            }
            if (eventData.endDate != null){
              eveEndD = eventData.endDate
            }
            allEvents[doc.id] = eventData

            if (eveStartD.toDate() <= timestampNow.toDate() 
            && timestampNow.toDate() <= eveEndD.toDate()){
              if (eventData.approved){
                expEvents.push({ ...eventData, id: doc.id})
              }
              
            }
            else if (eveStartD.toDate() > timestampNow.toDate()){
              if (eventData.approved){
                expEvents.push({ ...eventData, id: doc.id})
              }
            }
        })

        expEvents = expEvents.sort((eveItm1, eveItm2) => {
          return eveItm1.startDate.toDate() - eveItm2.startDate.toDate()
        })
        
        setExploreEvents(expEvents)
        setEventsAll(allEvents)
    })

  }

  function loadNotifications(){
    var userUID = ""
    userUID = auth.currentUser.uid
    fires
    .collection('notifications')
    .where('toUID','==',userUID)
    .where('status','==','sent')
    .orderBy('timestamp','desc')
    .limit(50)
    .onSnapshot((snapshotA)=>{
      var expNotifications = []
      snapshotA.docs.forEach((docA)=>{
        const docId = docA.id
        const docData = docA.data()
        expNotifications.push({...docData, id: docId})

      })
      setExploreNotifications(expNotifications)
      
    })
  }

  function loadPaymentSessions(){
    var userUID = ""
    userUID = auth.currentUser.uid
    fires
    .collection('service-stripe-customers')
    .doc(userUID)
    .collection('payments')
    .get()
    .then((snapshot) => {
      var payments = []
      snapshot.docs.map((paymentItm) => {
        const paymentIdd = paymentItm.id
        let paymentD = paymentItm.data()
        paymentD.id = paymentIdd
        payments.push(paymentD)
      })
      setPaymentItems(payments)
    })
  }

  function loadUserGroups(){
    var userUID = ""
    userUID = auth.currentUser.uid
    fires
    .collection('users')
    .doc(userUID)
    .collection('groups')
    .orderBy('timestamp','desc')
    .limit(50)
    .get()
    .then((snapshotA)=>{
      var expGroups = []
      snapshotA.docs.forEach((docA)=>{
        const docId = docA.id
        const docData = docA.data()
        expGroups.push({...docData, id: docId})
      })
      setUserGroups(expGroups)
      
    })
  }

  function loadOrgData(orgId){
    fires
    .collection('groups')
    .doc(orgId)
    .get()
    .then((snapshotA) => {
      const docId = snapshotA.id
      const docData = snapshotA.data()
      setUserOrg({...docData, id: docId})
    })
  }

  var unsubscribeChatAction = null
  function loadGroupChatMessages(messageId){
    
    unsubscribeChatAction = fires
    .collection('group_messages')
    .doc(messageId)
    .collection('messages')
    .orderBy('timestamp','asc')
    .onSnapshot((snapshotA)=>{
      var expMessages = []
      snapshotA.docs.forEach((docA)=>{
        const docId = docA.id
        const docData = docA.data()
        expMessages.push({...docData, id: docId})
      })
      setCurrentChatMessages(expMessages)
      
    })
  }

  function loadMyGroupChat(){
    var userUID = ""
    userUID = auth.currentUser.uid
    fires
    .collection('group_messages')
    .orderBy('timestamp','desc')
    .get()
    .then((snapshotA)=>{
      var expGroupChat = []
      snapshotA.docs.forEach((docA)=>{
        const docId = docA.id
        const docData = docA.data()
        if (docData.users != null){
          if (docData.users[userUID] != null){
            expGroupChat.push({...docData, id: docId})
          }
        }
      })
      setExploreMyGroupChat(expGroupChat)
      
    })

  }

  function loadGroupChat(eventIds){
    var userUID = ""
    userUID = auth.currentUser.uid
    var eIds = []
    if (eventIds.length >= 10){
      eIds = eventIds.slice(0,10)
    }
    else{
      eIds = eventIds
    }
    fires
    .collection('group_messages')
    .where('eventId','in',eIds)
    .where('visible','==','contact')
    .orderBy('timestamp','desc')
    .limit(50)
    .get()
    .then((snapshotA)=>{
      var expGroupChat = []
      snapshotA.docs.forEach((docA)=>{
        const docId = docA.id
        const docData = docA.data()
        if (docData.users != null){
          if (docData.users[userUID] != null){
            expGroupChat.push({...docData, id: docId})
          }
        }
      })
      setExploreGroupChat(expGroupChat)
      
    })
  }

  async function uploadImage(file) {
    return await fetch('https://r2-worker.sam-12e.workers.dev/', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': file.type
      },
      body: file
    })
      .then((response) => response.text())
      .then((data) => {
        return 'https://pub-e888c0363b7c412caf77f22ba84fcb86.r2.dev/'+data
      }) // 
      
  }

  useEffect(() => {
    fires
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const posts = [];
          const postData = snapshot.data();
          

          if (postData.profileURL != null){
            setProfileImg(postData.profileURL)
          }
          setUserData({...postData})
          loadPaymentSessions()
          if (postData.lang != null){
            if (postData.lang == 'en'){
              i18n.changeLanguage('en')
            }
            else if (postData.lang == 'zh'){
              i18n.changeLanguage('zh')
            }
            else{
              i18n.changeLanguage('zh')
            }
          }
          else{
            i18n.changeLanguage('zh')
          }
          
          if (postData.events != null){
            var uEvents = postData.events
            if (uEvents.length != 0) {
              fires
              .collection("events")
              .where('status','==','live')
              .orderBy('startDate','desc')
              .get()
              .then((snapshotA)=>{
                var userEventList = []
                var userEventids = []
                snapshotA.docs.map((eventD)=>{
                  const eId = eventD.id
                  const eData = eventD.data()
                  if (uEvents.includes(eId)){
                    userEventList.push({...eData, id: eId})
                    userEventids.push(eId)
                  }
                })
                setUserEventIds(userEventids)
                setUserEvents(userEventList)

                //load group chats
                if (userEventids.length > 0){
                  loadGroupChat(userEventids)
                }

              })
            }
          }
          //org profile
          if (postData.orgId != null){
            if (postData.orgId != ""){
              loadOrgData(postData.orgId)
            }
          }
          //load post
          // loadExplorePosts(postLoadNum)

          //load notification
          loadNotifications()

          //load explore events
          loadExploreEvents()

          //load my group chat
          loadMyGroupChat()

          //load user group
          loadUserGroups()

          //load user post
          loadMyPosts(postLoadNum)
          
          
          if (postData.currentEvent !== null) {
            const eventId = postData.currentEvent;
            if (eventId != ""){
              fires
              .collection("events")
              .doc(eventId)
              .get()
              .then((snapshotA) => {
                if (snapshotA.exists) {
                  const eventD = snapshotA.data()

                  var eventTitles = {en: '', zh: ''}
                  var eventDescriptions = {en: [], zh: []}
                  var eventAddress = {fullAddress: ''}
                  var startD = firebase.firestore.Timestamp.fromDate(new Date())
                  var endD = firebase.firestore.Timestamp.fromDate(new Date())

                  if (eventD.titles != null){
                    eventTitles = eventD.titles
                  }
                  else{
                    eventTitles = {en: eventD.title, zh: eventD.title}
                  }
                  if (eventD.descriptions != null){
                    eventDescriptions = eventD.descriptions
                  }
                  else{
                    eventDescriptions = {en: [eventD.description], zh: [eventD.description]}
                  }
                  if (eventD.address != null){
                    if (eventD.address.fullAddress != null){
                      eventAddress = eventD.address
                    }
                    else{
                      eventAddress = eventD.address
                    }
                  }
                  if (eventD.startDate != null){
                    startD = eventD.startDate
                  }
                  if (eventD.endDate != null){
                    endD = eventD.endDate
                  }
                  setEventDetails({ 
                    ...eventD, 
                    id: snapshotA.id, 
                    titles: eventTitles, 
                    descriptions: eventDescriptions,
                    address: eventAddress,
                    startDate: startD, 
                    endDate: endD})
                  setEventExist(true)
                    
                }
                else{
                  setEventExist(false)
                }
              });
            }
          }
          else{
            logout()
            window.location.reload()
          }
          if (postData.length === 0) {
            posts.push({
              id: id,
              error: "NoAccess",
              error_message: "没有访问权限",
              error_code: 404,
            });
            setPosts(posts);
            document.title = "没有访问权限";
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", "请您查看是否具有访问权限");
            document.description = "请您查看是否具有访问权限";
          } else {
            posts.push({ ...postData, id: id });
            setPosts(posts);
            if (postData.profileURL != null){
              setSelectedImageURL(postData.profileURL)
            }
            document.title = postData.firstName + " account | Event Go | User Details";
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", postData.firstName);
          }
        } else {
          //deleted
          posts.push({
            id: id,
            error: "NotFound",
            error_message: "没有访问权限",
            error_code: 404,
          });
          setPosts(posts);
          document.title = "没有访问权限";
          document.description = "请您查看是否具有访问权限";
        }
      });
  }, []);

  return (
    <div>
      {posts.map((post) => {
        const error = post.error;

        const postTopics = post.topics;
        const viewByData = post.viewBy;

        if (error === "NotFound" || error === "NoAccess") {
          return (
            <Container
              className="d-flex align-items-center justify-content-center"
              style={{ minHeight: "100vh" }}
            >
              <div className="w-100" style={{ maxWidth: maxW }}>
                <Card>
                  <Helmet>
                    <title>无法显示</title>
                    <meta name="description" content="无法显示" />
                  </Helmet>
                  <Card.Body>
                    <Card.Title>404</Card.Title>
                    <Card.Text>该用户已删除或内容不存在</Card.Text>
                    <Card.Text>
                      User deleted or the post doesn't exist
                    </Card.Text>
                    <Card.Link href="/">返回首页</Card.Link>
                  </Card.Body>
                </Card>
                ;
              </div>
            </Container>
          );
        } else {
          return (
            <>
              <Helmet>
                <title>{post.title}</title>
                <meta name="description" content={post.description} />
                <meta name="twitter:card" content={post.photoURL} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={post.title} />
                <meta name="twitter:description" content={post.description} />
                <meta name="twitter:image" content={post.photoURL} />
                <meta property="og:title" content={post.title} />
                <meta property="og:description" content={post.description} />
                <meta property="og:image" content={post.photoURL} />
                <meta
                  property="og:url"
                  content={"https://tickets.evtgo.com/" + post.id}
                />
                <meta property="og:site_name" content={post.title} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>

              <Modal
                  show={showCurrentChat}
                  onHide={() => {
                    setShowCurrentChat(false);
                    if (unsubscribeChatAction != null){
                      unsubscribeChatAction()
                    }
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {currentChatDetails.roomTitle}({Object.keys(currentChatDetails.users).length})
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ListGroup>
                      {
                        currentChatMessages.map((messageItm) => {
                          
                          return <ListGroup.Item
                          className="d-flex align-items-center border-0"
                          style={{
                            alignSelf: "center",
                            display: "flex",
                            width: "100%",
                            marginBottom: "5px",
                          }}
                        >
                          <Image
                            roundedCircle
                            src={
                              (messageItm.profileURL != null ? messageItm.profileURL : profileImg) 
                            }
                            width="25px"
                            height="25px"
                          />
                          <div style={{ marginLeft: "5px" }}>
                            <Card.Text
                              style={{
                                fontSize: "12px",
                                fontWeight: "normal",
                                marginBottom: "0px",
                                color: "black",
                              }}
                            >
                              {messageItm.content}
                            </Card.Text>
                          </div>
                        </ListGroup.Item>

                        })
                      }

                    </ListGroup>

                  </Modal.Body>

                  <Modal.Footer>
                    <Form inline>
                    <Form.Group className="d-flex align-items-start justify-content-between">
                        <Form.Control
                            style={{width: '350px'}}
                            type="text"
                            ref={messageChatRef}
                            placeholder="Type your message"
                        />
                        <Button style={{marginLeft: '15px'}} onClick={()=>{
                          //send message
                          const timestampNow = firebase.firestore.Timestamp.fromDate(new Date())
                          if (currentChatDetails.id != ""){
                            const messageEntered = messageChatRef.current.value
                            if (messageEntered != ""){
                              fires.collection('group_messages').doc(currentChatDetails.id).collection('users').doc(auth.currentUser.uid).set({
                                timestamp: timestampNow,
                                userName: userData.userName,
                                profileURL: userData.profileURL
                              })
                              const messageNew = {
                                content: messageEntered,
                                sender: auth.currentUser.uid,
                                status: 'sent',
                                type: 'text',
                                profileURL: userData.profileURL,
                                timestamp: timestampNow
                              }
                              fires.collection('group_messages').doc(currentChatDetails.id).collection('messages').add(messageNew).then((mRef) => {
                                fires.collection('group_messages').doc(currentChatDetails.id).update({
                                  messFromUID: auth.currentUser.uid,
                                  messPreview: messageEntered,
                                  messTimestamp: timestampNow
                                })
                                
                                messageChatRef.current.value = ""

                              })
                            }
                          }
                          
                          
                        }} variant="outline-info">
                            Send
                        </Button>
                    </Form.Group>
                    </Form>
                  </Modal.Footer>

                </Modal>

              <Modal
                  show={showCreatePost}
                  onHide={() => {
                    setShowCreatePost(false);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Create Post
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group className="mb-3" controlId="register.email">
                        <Form.Label style={{fontSize: '18px', fontWeight: 'bold'}}>
                          What's on your mind?
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          defaultValue=""
                          rows={3}
                          placeholder="What's on your mind?"
                          autoFocus
                          ref={postContentRef}
                          required
                        />
                      </Form.Group>
                      <ListGroup>
                      {
                      postImageSet.map((img)=>{
                        return <ListGroup.Item>
                                <Card.Img src={img} style={{maxHeight: '400px'}} />
                                <div style={{marginTop: '15px', marginBottom: '15px'}}>
                                  <Button variant="dark">Modify</Button>
                                  <Button variant="danger" style={{marginLeft: '15px'}}>Delete</Button>
                                </div>
                              </ListGroup.Item>
                      })
                    }
                      </ListGroup>
                      {/* <Form.Group>
                        
                      <Carousel style={{marginTop: '25px'}}>
                    {
                      postImageSet.map((img)=>{
                        return <Carousel.Item>
                                <Card.Img src={img} />
                              </Carousel.Item>
                      })
                    }
                  </Carousel>
                      </Form.Group> */}

                      <Form.Group 
                              id="user-profile" 
                              style={{
                                marginTop: '15px', 
                                marginBottom: '15px'}}>
                            <Form.Label style={{fontSize: '18px', fontWeight: 'bold'}}>
                              Add Pictures
                            </Form.Label>
                            <Form.Control
                          type="text"
                          defaultValue=""
                          rows={1}
                          placeholder="Enter Image URL here"
                          autoFocus
                          onChange={(e)=>{
                            const enteredImage = e.target.value
                          }}
                          required
                        />
                            {selectedPostImage && (
                              <div>
                              <img 
                              alt="not found" 
                              width={"250px"} 
                              src={URL.createObjectURL(selectedPostImage)} 
                              />
                              <br />
                              <Alert 
                              variant="primary"
                              >{uploadS}
                              </Alert>
                              <div>
                                <button 
                                onClick={()=>setSelectedPostImage(null)}
                                >Remove</button>
                                <button 
                                onClick={handlePostImageSubmit}
                                >Upload</button>
                              </div>
                              </div>
      )}
      <br />
      <input
        type="file"
        name="coverImage"
        title="Choose Image"
        onChange={handlePostImageChange}
      />
      <br /><br />
        </Form.Group>

                      
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                  <Button
                      variant="dark"
                      onClick={() => {
                        handlePostCreateSubmit()
                      }}
                    >
                      Publish
                    </Button>
                  <Button
                      variant="secondary"
                      onClick={() => {
                        setShowCreatePost(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>

              <Container
                className="d-flex justify-content-center"
                style={{ minHeight: "100vh", minWidth: "100vw" }}
              >
                <div
                  className="w-100"
                  style={{
                    marginTop: "25px",
                    marginBottom: "25px",
                    maxWidth: maxW
                  }}
                >


                  <div>
                    <Card className={"border-0"}>
                      <Card.Body>
                      <Alert variant="danger" hidden={!auth.currentUser.isAnonymous}>You are currently login as guest, please create account with your email now.</Alert>
                      <div className="d-flex justify-content-between align-items-start">
                      <Card.Title
                          style={{
                            fontSize: isMobile ? '30px' : "45px",
                            fontWeight: "bold",
                            color: "#000000",
                          }}
                        >
                          {t('user_details_label_greeting') + ' ' + post.firstName + ' 👋'}
                        </Card.Title>
                        <div style={{display: 'flex'}}>

                        
                        

                        <DropdownButton
                  style={{marginRight: '10px'}}
                  title={i18n.language == 'en' ? 'My Account' : '账户设置'}
                  id=""
                  variant="outline-dark"
                  onSelect={(e)=>{
                    if (e == 'logout'){
                      logout();
                      window.location.href = "/";
                    }
                    else if (e == 'account-language'){
                        if (i18n.language == 'en'){
                            //change to zh
                            i18n.changeLanguage('zh')
                            const uidd = auth.currentUser.uid
                            fires.
                            collection('users').
                            doc(uidd).
                            update({lang: 'zh'})
                        }
                        else{
                            i18n.changeLanguage('en')
                            const uidd = auth.currentUser.uid
                            fires.
                            collection('users').
                            doc(uidd).
                            update({lang: 'en'})
                        }
                    }
                    else if (e == 'create-account') {
                      if (userData.email != null && userData.email != ""){
                        //create account with this
                        var alertPassword = window.prompt("Please choose password for your account " + userData.email)
                        if (alertPassword != ""){
                          var credential = firebase.auth.EmailAuthProvider.credential(userData.email, alertPassword);
                          auth.currentUser.linkWithCredential(credential).then((userObj) => {
                            window.alert('Success! You have successfully creating EventGo account!')
                            window.location.reload()
                          })

                        }
                        
                      }
                    }
                  }}
                >
                  <Dropdown.Item eventKey='account-setting'>{i18n.language == 'en' ? 'My Account' : '我的账户'}</Dropdown.Item>
                  <Dropdown.Item eventKey='account-language'>{i18n.language == 'en' ? '简体中文版' : 'English'}</Dropdown.Item>
                  <Dropdown.Item eventKey='create-account' style={{color: 'green'}} hidden={!auth.currentUser.isAnonymous}>Create Account</Dropdown.Item>
                  <Dropdown.Divider/>
                  <Dropdown.Item eventKey='logout' style={{color: 'red'}}>{i18n.language == 'en' ? 'Logout' : '退出'}</Dropdown.Item>
                </DropdownButton>
                        </div>
                      </div>

                      
                        

                        <Tab.Container id="list-group-tabs-example" defaultActiveKey={window.location.href.includes('#partnership') ? '#partnership' : '#payment-info'}>
                      <Row  style={{marginTop: '50px'}}>
                          <Col sm={2}>
                            <ListGroup>
                            <ListGroup.Item action href="#payment-info" className="border-0 d-flex align-items-center"
                            style={{
                              alignSelf: "center",
                              display: "flex",
                              width: "100%",
                              marginBottom: "5px",
                              }}>
                              <FaCalendarAlt/><div className="fw-bold" style={{marginLeft: '5px'}}>{t('user_details_tab_section_events')}</div>
                             </ListGroup.Item>
                             <ListGroup.Item action href="#notification-info" className="border-0 d-flex align-items-center"
                             style={{
                              alignSelf: "center",
                              display: "flex",
                              width: "100%",
                              marginBottom: "5px",
                              }}>
                              <FaBell/><div className="fw-bold" style={{marginLeft: '5px'}}>{t('user_details_tab_section_notifications')}</div>
                             </ListGroup.Item>
                             <ListGroup.Item action href="#partnership" className="border-0 d-flex align-items-center"
                             style={{
                              alignSelf: "center",
                              display: "flex",
                              width: "100%",
                              marginBottom: "5px",
                              }}
                              hidden={userData.partner == null}>
                              <FaBullhorn/><div className="fw-bold" style={{marginLeft: '5px'}}>{i18n.language == 'en' ? "Partnership" : '合作计划'}</div>
                             </ListGroup.Item>
                            
                             <ListGroup.Item action href="#search" className="border-0 d-flex align-items-center"
                             style={{
                              alignSelf: "center",
                              display: "flex",
                              width: "100%",
                              marginBottom: "5px",
                              }}>
                              <FaSearch/><div className="fw-bold" style={{marginLeft: '5px'}}>{t('user_details_tab_section_search')}</div>
                             </ListGroup.Item>
                             <ListGroup.Item action href="#other-info" className="border-0 d-flex align-items-center"
                             style={{
                              alignSelf: "center",
                              display: "flex",
                              width: "100%",
                              marginBottom: "5px",
                              }}>
                              <FaUserAlt/><div className="fw-bold" style={{marginLeft: '5px'}}>{t('user_details_tab_section_me')}</div>
                             </ListGroup.Item>
                            </ListGroup>
                              
                          </Col>
                          <Col sm={10}>
                          <Tab.Content>
                            <Tab.Pane eventKey="#partnership">
                            <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}} hidden={!userData.sponsor}>{i18n.language == 'en' ? 'Event Partnership' : '活动合作伙伴'}</Card.Text>
                            </div>
                            <ListGroup style={{marginTop: '15px'}}>
                              <ListGroup.Item>
                                <div>Partnership Status</div>
                                <div className="fw-bold">Pending</div>
                              </ListGroup.Item>
                            </ListGroup>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#payment-info">

                            <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}} hidden={paymentItems.length == 0}>{i18n.language == 'en' ? 'Payment Info' : '付款信息'}</Card.Text>
                            </div>

                            <ListGroup style={{marginTop: '15px'}}>
                              {
                                paymentItems.map((paymentItm) => {
                                  let paymentId = paymentItm.id
                                  var paymentStatus = ""
                                  var eveId = ""
                                  var price = 0.0
                                  var quantity = "1"
                                  var ticketName = ""
                                  var statusTimestamp = firebase.firestore.Timestamp.fromDate( new Date() )
                                  if (paymentItm.status != null){
                                    paymentStatus = paymentItm.status
                                  }
                                  if (paymentItm.statusTimestamp != null){
                                    statusTimestamp = paymentItm.statusTimestamp
                                  }
                                  if (paymentItm.metadata != null){
                                    eveId = paymentItm.metadata.eventId
                                    price = paymentItm.metadata.price
                                    quantity = paymentItm.metadata.quantity
                                    if (paymentItm.metadata.ticketName != null){
                                      ticketName = paymentItm.metadata.ticketName
                                    }
                                  }

                                  var eveDetails = {
                                    title: eveId,
                                    description: '',
                                    startDate: firebase.firestore.Timestamp.fromDate( new Date() ),
                                    endDate: firebase.firestore.Timestamp.fromDate( new Date() ),
                                    address: {
                                      fullAddress: "",
                                    }
                                  }
                                  if (eventDetails.id == eveId){
                                    eveDetails = eventDetails
                                  }
                                  var statusLocal = ""
                                  if (i18n.language == 'zh'){
                                    if (paymentStatus == "ticketed"){
                                      statusLocal = "已出票"
                                    }
                                    else if (paymentStatus == "succeeded"){
                                      //no ticket yet
                                      statusLocal = "成功支付，点击出票"
                                    }
                                    else {
                                      statusLocal = "未知状态"
                                    }
                                  }
                                  else{
                                    if (paymentStatus == "ticketed"){
                                      statusLocal = "Ticket"
                                    }
                                    else if (paymentStatus == "succeeded"){
                                      //no ticket yet
                                      statusLocal = "Payment success"
                                    }
                                    else {
                                      statusLocal = "NA"
                                    }
                                  }

                                  if (Object.keys(eventsAll).includes(eveId)){
                                    eveDetails = eventsAll[eveId]
                                  }

                                  return <ListGroup.Item className="border-0">
                                    <div
                                           className="d-flex align-items-center"
                                           style={{
                                             alignSelf: "center",
                                             display: "flex",
                                             width: "100%",
                                             marginBottom: "5px",
                                           }}
                                         >
                                    <Card.Img style={{width: '100px', height: '100px', objectFit: 'cover'}} src={eveDetails.photoURL} />
                                    <div style={{ marginLeft: '15px' }}>
                                             <Card.Text style={{fontSize: '14px', fontWeight: 'bold', color: '#F6AC19', margin: 0}}>{ticketName} {statusLocal} Qty: {quantity} @ ${price}</Card.Text>
                                             <Card.Text style={{fontSize: '17px', fontWeight: 'bold', color: 'black', margin: 0}}>{eveDetails.title}</Card.Text>
                                             <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>{eveDetails.address.fullAddress}</Card.Text>
                                             <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>Last updated: {statusTimestamp.toDate().toLocaleString('en')}</Card.Text>
                                    </div>
                                    </div>
                                    <div style={{marginTop: '20px', marginBottom: '10px'}}>
                                      <Card.Link href={'/payment-confirmation/'+paymentId} style={{color: 'black', fontWeight: 'bold'}}>{i18n.language == 'en' ? '查看详情' : 'View Details'}</Card.Link>
                                      <Card.Link hidden={paymentStatus != 'succeeded'} href={'/payment-success/'+eveId} style={{color: 'black', fontWeight: 'bold'}}>{'出票'}</Card.Link>
                                      <Card.Link hidden={paymentStatus == 'succeeded' || paymentStatus == 'ticketed' } style={{color: 'black', fontWeight: 'bold'}} onClick={()=>{
                                        //pay again

                                      }}>继续支付</Card.Link>
                                    </div>
                                  </ListGroup.Item>

                                })
                              }
                            </ListGroup>
                            
                            
                            <Row style={{marginTop: '150px'}}>
                              <Col sm={10}>
                              
                              <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>{t('user_details_tab_other_events')}</Card.Text>
                              </div>
                              <div style={{marginTop: '25px'}}>
                              {
                                userEvents.map((eventItem) => {
                                  return <Card className="border-0" style={{width: '100%', marginTop: '25px'}} onClick={()=> {
                                      window.location.href = "/" + eventItem.id + "/" + i18n.language
                                      }}>
                              
                              <Card.Body>
                                                  <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                          alignSelf: "center",
                                                          display: "flex",
                                                          width: "100%",
                                                          marginBottom: "5px",
                                                        }}
                                                      >
                                                 <Card.Img style={{width: '100px', height: '100px', objectFit: 'cover'}} src={eventItem.photoURL} />
                                                        <div style={{ marginLeft: '15px' }}>
                                                          <Card.Text style={{fontSize: '12px', fontWeight: 'bold', color: '#F57828', margin: 0}}>{eventItem.startDate.toDate().toLocaleDateString('en')}</Card.Text>
                                                          <Card.Text style={{fontSize: '17px', fontWeight: 'bold', color: 'black', margin: 0}}>{eventItem.title}</Card.Text>
                                                          <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>{eventItem.address.fullAddress}</Card.Text>
                                                          <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>{eventItem.startDate.toDate().toLocaleString('en')}</Card.Text>
                                                        </div>
                                                      </div>
                                
                              </Card.Body>
                              </Card>
                                })
                              }
                            </div>

                              <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>{t('user_details_tab_explore_events')} ({exploreEvents.length})</Card.Text>
                              </div>

                              <div style={{marginTop: '25px'}}>
                              {
                                exploreEvents.map((eventItem) => {
                                  return <Card className="border-0" style={{width: '100%', marginTop: '25px'}} onClick={()=> {
                                      window.location.href = "/" + eventItem.id + "/" + i18n.language
                                      }}>
                              
                              <Card.Body>
                                                  <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                          alignSelf: "center",
                                                          display: "flex",
                                                          width: "100%",
                                                          marginBottom: "5px",
                                                        }}
                                                      >
                                                 <Card.Img style={{width: '100px', height: '100px', objectFit: 'cover'}} src={eventItem.photoURL} />
                                                        <div style={{ marginLeft: '15px' }}>
                                                          <Card.Text style={{fontSize: '12px', fontWeight: 'bold', color: '#F57828', margin: 0}}>{eventItem.startDate.toDate().toLocaleDateString('en')}</Card.Text>
                                                          <Card.Text style={{fontSize: '17px', fontWeight: 'bold', color: 'black', margin: 0}}>{eventItem.title}</Card.Text>
                                                          <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>{eventItem.address.fullAddress}</Card.Text>
                                                          <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>{eventItem.startDate.toDate().toLocaleString('en')}</Card.Text>
                                                        </div>
                                                      </div>
                                
                              </Card.Body>
                              </Card>
                                })
                              }
                            </div>

                              </Col>
                            </Row>
                            

                              </Tab.Pane>


                              <Tab.Pane hidden={true} eventKey="#explore-post">
                              <div className="d-flex align-items-start justify-content-between">
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>{t('user_details_tab_section_explore_posts')} ({explorePosts.length})</Card.Text>
                                <Button variant="dark" style={{fontSize: '15px', fontWeight: 'bold', height: '50px', borderRadius: '25px', marginRight: '15px'}} onClick={()=>{setShowCreatePost(true)}}>Create Post</Button>
                              </div>
                                <Row md={5} hidden={isMobile} style={{marginTop: '15px'}}>
                                  {
                                    explorePosts.map((postItm) => {
                                      return <Col style={{marginTop: '15px'}}>
                                      <Card className="border-0" onClick={()=>{
                                        window.location.href = "/post/"+postItm.id
                                      }}>
                                        <Card.Img variant="top" style={{maxHeight: '300px', borderRadius: '25px'}} src={postItm.imageURL}/>
                                        <Card.Title style={{marginTop: '15px', fontSize: '15px', fontWeight: 'bold'}}>{postItm.postContent.substring(0,50)}</Card.Title>

                                        <ListGroup.Item
                                  className="d-flex align-items-center"
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "5px",
                                  }}
                                  onClick={() => {
                                    
                                  }}
                                >
                                  <Image
                                    roundedCircle
                                    src={
                                      postItm.user.profileURL
                                    }
                                    width="25px"
                                    height="25px"
                                  />
                                  <div style={{ marginLeft: "5px" }}>
                                    <Card.Text
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "normal",
                                        marginBottom: "0px",
                                        color: "black",
                                      }}
                                    >
                                      {postItm.user.userName}
                                    </Card.Text>
                                    
                                  </div>
                                </ListGroup.Item>
                                      </Card>
                                      </Col>
                                    })
                                  }
                                </Row>

                                {
                                    explorePosts.map((postItm) => {
                                      return <Card className="border-0" style={{marginTop: '15px'}} hidden={!isMobile} onClick={()=>{
                                        window.location.href = "/post/"+postItm.id
                                      }}>
                                        <Card.Img variant="top" style={{maxHeight: '500px', borderRadius: '25px'}} src={postItm.imageURL}/>
                                        <Card.Title style={{marginTop: '15px', fontSize: '15px', fontWeight: 'bold'}}>{postItm.postContent.substring(0,50)}</Card.Title>

                                        <ListGroup.Item
                                  className="d-flex align-items-center"
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "5px",
                                  }}
                                  onClick={() => {
                                    
                                  }}
                                >
                                  <Image
                                    roundedCircle
                                    src={
                                      postItm.user.profileURL
                                    }
                                    width="25px"
                                    height="25px"
                                  />
                                  <div style={{ marginLeft: "5px" }}>
                                    <Card.Text
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "normal",
                                        marginBottom: "0px",
                                        color: "black",
                                      }}
                                    >
                                      {postItm.user.userName}
                                    </Card.Text>
                                    
                                  </div>
                                </ListGroup.Item>
                                      </Card>
                                    })
                                  }
                                

                                <div className="d-flex align-items-center justify-content-center">
                                  <Button
                                  style={{
                                    backgroundColor: 'transparent', 
                                    borderColor: 'transparent', 
                                    color: 'black',
                                    fontSize: '17px',
                                    fontWeight: 'bold'}}
                                    onClick={()=>{
                                      // loadExplorePosts(postLoadNum+50)
                                      // setPostLoadNum(postLoadNum+50)
                                    }}>Load More Posts</Button>
                                </div>
                              
                              </Tab.Pane>

                              <Tab.Pane eventKey="#chats-info">
                              <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>Chats</Card.Text>
                              </div>

                              <Row style={{marginTop: '15px'}}>
                              <Col sm={2}>
                              <ListGroup>
                              <ListGroup.Item action href="#group-message" className="border-0 d-flex align-items-center" 
                              style={{
                                alignSelf: "center",
                                display: "flex",
                                width: "100%",
                                marginBottom: "5px",
                              }}>
                              <FaCommentDots/><div className="fw-bold" style={{marginLeft: '5px'}}>{'Group Chats'}</div>
                             </ListGroup.Item>
                             <ListGroup.Item action href="#my-group-message" className="border-0 d-flex align-items-center" 
                              style={{
                                alignSelf: "center",
                                display: "flex",
                                width: "100%",
                                marginBottom: "5px",
                              }}>
                              <FaCommentDots/><div className="fw-bold" style={{marginLeft: '5px'}}>{'My Group Chats'}</div>
                             </ListGroup.Item>
                             <ListGroup.Item action href="#direct-message" className="border-0 d-flex align-items-center" 
                              style={{
                                alignSelf: "center",
                                display: "flex",
                                width: "100%",
                                marginBottom: "5px",
                              }}>
                              <FaCommentDots/><div className="fw-bold" style={{marginLeft: '5px'}}>{'DM'}</div>
                             </ListGroup.Item>
                             </ListGroup>

                              
                              </Col>
                              <Col sm={10}>
                              <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>{window.location.href.endsWith('#my-group-message') ? 'My Group Chats' : window.location.href.endsWith('#group-message') ? 'Group Chats' : 'DM Chats'}</Card.Text>
                              </div>
                                <ListGroup style={{marginTop: '15px'}} hidden={!window.location.href.endsWith('#group-message')}>
                                  {
                                    exploreGroupChat.map((gcItem) => {
                                      return <ListGroup.Item className="align-items-start" onClick={()=>{
                                        
                                        loadGroupChatMessages(gcItem.id)
                                        setCurrentChatDetails(gcItem)
                                        setShowCurrentChat(true)
                                        if (currentChatMessages.length > 0){
                                          
                                        }
                                        
                                        
                                      }}>
                                  <div 
                                  className="d-flex align-items-center border-0"
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "5px",
                                    marginTop: "5px",
                                  }}>

                                  <Image
                                    roundedCircle
                                    src={
                                      gcItem.roomImageURL
                                    }
                                    width="35px"
                                    height="35px"
                                  />
                                  <div style={{ marginLeft: "8px" }}>
                                    <Card.Text
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        marginBottom: "0px",
                                        color: "black",
                                      }}
                                    >
                                      {gcItem.roomTitle}
                                    </Card.Text>
                                  </div>
                                  </div>
                                  <Card.Text
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "normal",
                                        marginBottom: "10px",
                                        marginLeft: '43px',
                                        color: "black",
                                      }}
                                    >
                                      {gcItem.messPreview}
                                    </Card.Text>

                                  </ListGroup.Item>
                                    })
                                  }
                                </ListGroup>

                                <ListGroup style={{marginTop: '15px'}} hidden={!window.location.href.endsWith('#my-group-message')}>
                                  {
                                    exploreMyGroupChat.map((gcItem) => {
                                      return <ListGroup.Item className="align-items-start">
                                  <div 
                                  className="d-flex align-items-center border-0"
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "5px",
                                    marginTop: "5px",
                                  }} 
                                  onClick={()=>{
                                    loadGroupChatMessages(gcItem.id)
                                    setCurrentChatDetails(gcItem)
                                    setShowCurrentChat(true)
                                    if (currentChatMessages.length > 0){
                                      
                                    }
                                    
                                    
                                  }}>

                                  <Image
                                    roundedCircle
                                    src={
                                      gcItem.roomImageURL
                                    }
                                    width="35px"
                                    height="35px"
                                  />
                                  <div style={{ marginLeft: "8px" }}>
                                    <Card.Text
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        marginBottom: "0px",
                                        color: "black",
                                      }}
                                    >
                                      {gcItem.roomTitle}
                                    </Card.Text>
                                  </div>
                                  </div>
                                  <Card.Text
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "normal",
                                        marginBottom: "10px",
                                        marginLeft: '43px',
                                        color: "black",
                                      }}
                                    >
                                      {gcItem.messPreview}
                                    </Card.Text>

                                  </ListGroup.Item>
                                    })
                                  }
                                </ListGroup>
                              </Col>
                              </Row>


                              </Tab.Pane>
                              <Tab.Pane eventKey="#search">
                              <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>{t('user_details_tab_section_search')}</Card.Text>
                              </div>
                              <Form>
                                <Form.Group style={{marginTop: '15px'}}>
                                  <Form.Control
                                  as='textarea'
                                  rows={1}
                                  onChange={(e)=>{
                                    const searchText = e.target.value
                                    setSearchKeywords(searchText)

                                  }}
                                  placeholder='Search for events or posts'/>
                                  <Button variant="dark" style={{width: '100%', marginTop: '15px'}} onClick={(e)=>{
                                    if (searchKeywords != ""){
                                      
                                    }
                                  }}>Search</Button>
                                </Form.Group>
                              </Form>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#notification-info">
                              <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>{t('user_details_tab_section_notifications')}</Card.Text>
                              </div>
                              <Card.Text hidden={exploreNotifications.length > 0} style={{fontSize: '17px', fontWeight: 'normal', color: 'black', marginTop: '15px'}}>You have all caught up</Card.Text>
                              <ListGroup style={{marginTop: '15px'}}>
                              {
                                exploreNotifications.map((notiItm)=>{
                                  var notiActionType = ""
                                  var notiAs = "Event"
                                  var notiMessage = ""
                                  if (notiItm.type != null){
                                    if (notiItm.type == 'like'){
                                      notiActionType = "Liked"
                                    }
                                    else if (notiItm.type == 'comment'){
                                      notiActionType = "Commented"
                                    }
                                    else if (notiItm.type == 'welcome'){
                                      notiActionType = "Welcome"
                                    }
                                    else if (notiItm.type == 'login'){
                                      notiActionType = "Login"
                                    }
                                    else{
                                      notiActionType = ""
                                    }
                                  }
                                  if (notiItm.message != null){
                                    notiMessage = notiItm.message
                                  }
                                  return <ListGroup.Item
                                  className="border-0"
                                  onClick={() => {
                                    
                                  }}
                                >
                                  <div 
                                  className="d-flex align-items-center border-0"
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "5px",
                                    marginTop: "5px",
                                  }}>

                                  <Image
                                    roundedCircle
                                    src={
                                      profileImg
                                    }
                                    width="25px"
                                    height="25px"
                                  />
                                  <div style={{ marginLeft: "5px" }}>
                                    <Card.Text
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "normal",
                                        marginBottom: "0px",
                                        color: "black",
                                      }}
                                    >
                                      {notiItm.timestamp.toDate().toLocaleDateString('en')}
                                    </Card.Text>
                                  </div>
                                 

                                  </div>
                                  <Card.Text
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "normal",
                                        marginBottom: "0px",
                                        marginLeft: '30px',
                                        color: "black",
                                      }}
                                    >
                                      {notiActionType == 'Welcome' ? 'Welcome aboard, '+post.firstName : notiActionType == "Liked" ? 'Someone liked your post' : notiActionType == 'Commented' ? 'Someone commented on your post' : 'New Notification'}
                                    </Card.Text>
                                  <Card.Text
                                      style={{
                                        fontSize: "17px",
                                        fontWeight: "bold",
                                        marginBottom: "10px",
                                        marginLeft: '30px',
                                        color: "black",
                                      }}
                                    >
                                      {notiMessage}
                                    </Card.Text>
                                    <Card.Link
                                    onClick={()=>{
                                      fires
                                      .collection('notifications')
                                      .doc(notiItm.id)
                                      .update({status: 'read'})
                                      .then(()=>{
                                        
                                      })
                                    }}
                                    hidden={notiItm.status == 'read'}
                                    style={{
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                      marginBottom: "0px",
                                      marginTop: '25px',
                                      marginLeft: '30px',
                                      color: "black",
                                    }}>Mark as Read</Card.Link>
                                  <Button variant>

                                  </Button>
                                </ListGroup.Item>
                                  
                                })
                              }
                               
                              </ListGroup>

                              </Tab.Pane>

                              <Tab.Pane eventKey="#other-info">
                              <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>{t('user_details_tab_section_me')}</Card.Text>
                              </div>

                              <Row style={{ marginTop: '15px'}}>
                              <Col sm={2}>

                              <ListGroup>
                              <ListGroup.Item action href="#my-edit-info" className="border-0 d-flex align-items-center" 
                              style={{
                                alignSelf: "center",
                                display: "flex",
                                width: "100%",
                                marginBottom: "5px",
                              }}>
                              <FaCommentDots/><div className="fw-bold" style={{marginLeft: '5px'}}>{t('user_details_me_section_my_edit_info')}</div>
                             </ListGroup.Item>
                              <ListGroup.Item action href="#my-posts" className="border-0 d-flex align-items-center" 
                              style={{
                                alignSelf: "center",
                                display: "flex",
                                width: "100%",
                                marginBottom: "5px",
                              }}>
                              <FaCommentDots/><div className="fw-bold" style={{marginLeft: '5px'}}>{t('user_details_me_section_my_posts')} ({userPosts.length})</div>
                             </ListGroup.Item>
                             <ListGroup.Item action href="#my-events" className="border-0 d-flex align-items-center" 
                              style={{
                                alignSelf: "center",
                                display: "flex",
                                width: "100%",
                                marginBottom: "5px",
                              }}>
                              <FaCalendarAlt/><div className="fw-bold" style={{marginLeft: '5px'}}>{t('user_details_me_section_my_events')} ({userEvents.length})</div>
                             </ListGroup.Item>
                             <ListGroup.Item action href="#my-groups" className="border-0 d-flex align-items-center" 
                              style={{
                                alignSelf: "center",
                                display: "flex",
                                width: "100%",
                                marginBottom: "5px",
                              }}>
                              <FaUserAlt/><div className="fw-bold" style={{marginLeft: '5px'}}>{t('user_details_me_section_my_groups')} ({userGroups.length})</div>
                             </ListGroup.Item>
                             </ListGroup>

                              
                              </Col>
                              <Col sm={8}>

                              {success && (
                              <Alert variant="success">{success}</Alert>
                            )}

                            <Form onSubmit={handleInfoSubmit} hidden={!window.location.href.endsWith('#my-edit-info')}>
                              <Form.Group
                                id="email"
                                style={{ marginTop: "15px" }}
                              >
                                <FloatingLabel
                                label={t('user_details_user_info_email')}>
                                <Form.Control
                                  type="email"
                                  defaultValue={post.email}
                                  required
                                  disabled
                                />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group
                                id="name"
                                style={{ marginTop: "15px" }}
                              >
                                <FloatingLabel
                                label={t('user_details_user_info_firstname')}>
                                <Form.Control
                                  type="text"
                                  defaultValue={post.firstName}
                                  required
                                />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group
                                id="name"
                                style={{ marginTop: "15px" }}
                              >
                                <FloatingLabel
                                label={t('user_details_user_info_lastname')}>
                                <Form.Control
                                  type="text"
                                  defaultValue={post.lastName}
                                  required
                                />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group
                                id="work"
                                style={{ marginTop: "15px" }}
                              >
                                <FloatingLabel
                                label={t('user_details_user_info_work')}>
                                <Form.Control
                                  type="text"
                                  defaultValue={post.work}
                                />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group
                                id="wechat"
                                style={{ marginTop: "15px" }}
                              >
                                <FloatingLabel
                                label={t('user_details_user_info_wechat')}>
                                <Form.Control
                                  type="text"
                                  defaultValue={post.wechat}
                                />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group 
                              id="user-profile" 
                              style={{
                                marginTop: '15px', 
                                marginBottom: '15px'}}>
                            <Form.Label 
                            style={{
                              fontWeight: 'bold'
                              }}>{t('user_details_user_info_choose_pic')}</Form.Label>
                            {selectedImage && (
                              <div>
                              <img 
                              alt="not found" 
                              width={"250px"} 
                              src={URL.createObjectURL(selectedImage)} 
                              />
                              <br />
                              <Alert 
                              variant="primary"
                              >{uploadS}
                              </Alert>
                              <div>
                                <button 
                                onClick={()=>setSelectedImage(null)}
                                >Remove</button>
                                <button 
                                onClick={handleImageSubmit}
                                >Upload</button>
                              </div>
                              </div>
      )}
      <br />
      <input
        type="file"
        name="coverImage"
        title="Choose Image"
        onChange={handleImageChange}
      />
      <br /><br />
      <div>
      <img alt="profile" width={"100px"} height={"100px"} src={selectedImageURL}/>
      </div>
        </Form.Group>
      
                              <Button
                                className="w-100"
                                type="submit"
                                style={{
                                  marginTop: "15px",
                                  color: "white",
                                  background: "black",
                                }}
                              >
                                {t('user_details_action_modify_user_info')}
                              </Button>
                            </Form>

                            <ListGroup hidden={!window.location.href.endsWith('#my-posts')}>
                              <ListGroup.Item className="fw-bold border-0">My Posts ({userPosts.length})</ListGroup.Item>


                              <Row md={3} style={{marginTop: '15px'}}>
                                  {
                                    userPosts.map((postItm) => {
                                      return <Col style={{marginTop: '15px'}}>
                                      <Card className="border-0" onClick={()=>{
                                        window.location.href = "/post/"+postItm.id
                                      }}>
                                        <Card.Img variant="top" style={{maxHeight: '300px', borderRadius: '25px'}} src={postItm.imageURL}/>
                                        <Card.Title style={{marginTop: '15px', fontSize: '15px', fontWeight: 'bold'}}>{postItm.postContent.substring(0,50)}</Card.Title>

                                        <ListGroup.Item
                                  className="d-flex align-items-center border-0"
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "5px",
                                  }}
                                  onClick={() => {
                                    
                                  }}
                                >
                                  <Image
                                    roundedCircle
                                    src={
                                      postItm.user.profileURL
                                    }
                                    width="25px"
                                    height="25px"
                                  />
                                  <div style={{ marginLeft: "5px" }}>
                                    <Card.Text
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "normal",
                                        marginBottom: "0px",
                                        color: "black",
                                      }}
                                    >
                                      {postItm.user.userName}
                                    </Card.Text>
                                    
                                  </div>
                                </ListGroup.Item>
                                      </Card>
                                      </Col>
                                    })
                                  }
                                </Row>

                            </ListGroup>

                            <ListGroup hidden={!window.location.href.endsWith('#my-events')}>
                              <ListGroup.Item className="fw-bold border-0">My Events ({userEvents.length})</ListGroup.Item>

                              {

userEvents.map((eveItm) => {
  return <ListGroup.Item className="align-items-start border-0" onClick={()=>{
  }}>
<div 
className="d-flex align-items-center border-0"
style={{
alignSelf: "center",
display: "flex",
width: "100%",
marginBottom: "5px",
marginTop: "5px",
}}>

<Image
roundedCircle
src={
  eveItm.photoURL
}
width="35px"
height="35px"
/>
<div style={{ marginLeft: "8px" }}>
<Card.Text
  style={{
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "0px",
    color: "black",
  }}
>
  {eveItm.title}
</Card.Text>
</div>
</div>
<Card.Text
  style={{
    fontSize: "15px",
    fontWeight: "normal",
    marginBottom: "10px",
    marginLeft: '43px',
    color: "black",
  }}
>
  {eveItm.description.substring(0,100)}
</Card.Text>

</ListGroup.Item>
})

                              }
                            </ListGroup>

                            <ListGroup hidden={!window.location.href.endsWith('#my-groups')}>
                              <ListGroup.Item className="fw-bold border-0">My Groups ({userGroups.length})</ListGroup.Item>
                              
                              {
                                    userGroups.map((groupItem) => {
                                      return <ListGroup.Item className="align-items-start border-0" onClick={()=>{
                                        window.location.href = "/organizer/"+groupItem.id
                                      }}>
                                  <div 
                                  className="d-flex align-items-center border-0"
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "5px",
                                    marginTop: "5px",
                                  }}>

                                  <Image
                                    roundedCircle
                                    src={
                                      groupItem.imageURL
                                    }
                                    width="35px"
                                    height="35px"
                                  />
                                  <div style={{ marginLeft: "8px" }}>
                                    <Card.Text
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        marginBottom: "0px",
                                        color: "black",
                                      }}
                                    >
                                      {groupItem.name}
                                    </Card.Text>
                                  </div>
                                  </div>
                                  <Card.Text
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "normal",
                                        marginBottom: "10px",
                                        marginLeft: '43px',
                                        color: "black",
                                      }}
                                    >
                                      {groupItem.description}
                                    </Card.Text>

                                  </ListGroup.Item>
                                    })
                                  }
                            </ListGroup>

                              </Col>
                              </Row>
                              
                              
                              </Tab.Pane>
                          </Tab.Content>
                         

                          </Col>
                        </Row>

                        </Tab.Container>
                        

                  


                        
                      </Card.Body>
                    </Card>
                  </div>
                </div>
                  
              </Container>
            </>
          );
        }
      })}
    </div>
  );
}
