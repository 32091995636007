import React, { useContext, useState, useEffect } from "react";
import app, { auth, fires } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";


const API_ROOT_URL = "https://us-central1-blink-574af.cloudfunctions.net/"

async function checkTicketPrices(eventId){
    await fetch(API_ROOT_URL + "eventsAPICheckTicketStatus?event_id="+eventId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    })
    .then(response => response.json())
    .then((data) => {
        console.log(data)
    })
}
//image upload
async function uploadImageToServer(file) {
    return await fetch('https://r2-worker.sam-12e.workers.dev/', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': file.type
      },
      body: file
    })
      .then((response) => response.text())
      .then((data) => {
        return 'https://pub-e888c0363b7c412caf77f22ba84fcb86.r2.dev/'+data
      }) 
  }

  

//mail services
export function sendEventEmail(email,eventTitle,lang,eventStartDate,eventAddress,eventId,userName,ticketId,orgName){
    
    if (lang == "zh"){
        const messageSubject = "<" + orgName + ">"+"的活动已成功预约 - "+eventTitle
    
        const htmlSubject = "<h2>" + orgName + "的活动已成功预约</h2>"
        const body01 = "<p>您好 " + userName + ",</p><br>"
        const body02 = "<p>您的活动<b>" + eventTitle + "</b>已成功预约，请认准本次的门票ID「" + ticketId + "」</p>"
        const body03 = "<p>以下是本次活动的详细信息</p>"
        const body04 = "<p>时间: <b>" + eventStartDate + "</b></p>"
        const body05 = "<p>地址: <b>" + eventAddress + "</b></p>"
        const body06 = "<p>门票: <b>" + ticketId + "</b></p>"
        const body07 = "<h4>详细内容请点击<a href='https://evtgo.com/" + eventId  +"'>活动链接</a>并于活动当天展示<a href='https://evtgo.com/ticket-id/" + ticketId + "'>此活动门票</a>签到</h4><br>"
        const body08 = "<p>发现更多活动，欢迎下载<a href='https://leyuan.page.link/app'>乐源APP</a></p>"
        const body09 = "<br><p><b>" + orgName + "</b></p>"
    
    
        const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + body06 + body07 + body08 + body09
        sendHTMLEmailTo(email,messageSubject,htmlString)
    }
    else if (lang == "en"){

        const messageSubject = "Event from <" + orgName + ">"+" has been scheduled - "+eventTitle
    
        const htmlSubject = "<h2>" + orgName + " event has been scheduled successfully</h2>"
        const body01 = "<p>Hello " + userName + ",</p><br>"
        const body02 = "<p>You have been successfully scheduled event <b>" + eventTitle + "</b>. Please note your ticket ID is「" + ticketId + "」</p>"
        const body03 = "<p>The following is the event information</p>"
        const body04 = "<p>Date/Time: <b>" + eventStartDate + "</b></p>"
        const body05 = "<p>Address: <b>" + eventAddress + "</b></p>"
        const body06 = "<p>Ticket: <b>" + ticketId + "</b></p>"
        const body07 = "<h4>For more information please click <a href='https://evtgo.com/" + eventId  +"'>Event Link</a> and checkin using this email</h4><br>"
        const body08 = "<p>Discover more event like this，please download <a href='https://leyuan.page.link/app'>Letjoy APP</a></p>"
        const body09 = "<br><p><b>" + orgName + "</b></p>"
    
    
        const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + body06 + body07 + body08 + body09
        sendHTMLEmailTo(email,messageSubject,htmlString)
    }
    else{
        const messageSubject = "<" + orgName + ">"+"的活动已成功预约 - "+eventTitle

    const htmlSubject = "<h2>" + orgName + "的活动已成功预约</h2>"
    const body01 = "<p>您好 " + userName + ",</p><br>"
    const body02 = "<p>您的活动<b>" + eventTitle + "</b>已成功预约，请认准本次的门票ID「" + ticketId + "」</p>"
    const body03 = "<p>以下是本次活动的详细信息</p>"
    const body04 = "<p>时间: <b>" + eventStartDate + "</b></p>"
    const body05 = "<p>地址: <b>" + eventAddress + "</b></p>"
    const body06 = "<p>门票: <b>" + ticketId + "</b></p>"
    const body07 = "<p>详细内容请点击<a href='https://events.letjoy.app/" + eventId  +"'>活动链接</a></p><br>"
    const body08 = "<p>发现更多活动，欢迎下载<a href='https://leyuan.page.link/app'>乐源APP</a></p>"
    const body09 = "<br><p><b>" + orgName + "</b></p>"


    const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + body06 + body07 + body08 + body09
    sendHTMLEmailTo(email,messageSubject,htmlString)
    }
}


export function sendEventInvitedEmail(email,friendName,friendEmail,eventTitle,lang,eventStartDate,eventAddress,eventId,userName,ticketId,orgName){
    
    if (lang == "zh"){
        const messageSubject = "您的朋友 "+friendName+" 已帮您预约活动 - "+eventTitle
    
        const htmlSubject = "<h2>" + orgName + "的活动已成功预约，如有需要请联系<b>"+friendName+"</b></h2>"
        const body01 = "<p>您好 " + userName + ",</p><br>"
        const body02 = "<p>您的活动<b>" + eventTitle + "</b>已成功预约，请认准本次的门票ID「" + ticketId + "」</p>"
        const body03 = "<p>以下是本次活动的详细信息</p>"
        const body04 = "<p>时间: <b>" + eventStartDate + "</b></p>"
        const body05 = "<p>地址: <b>" + eventAddress + "</b></p>"
        const body06 = "<p>门票: <b>" + ticketId + "</b></p>"
        const body07 = "<p>您的朋友: <b>" + friendName + "</b> 电子邮箱 <b>"+friendEmail+"</b></p>"
        const body08 = "<p>详细内容请点击<a href='https://events.letjoy.app/" + eventId  +"'>活动链接</a></p><br>"
        const body09 = "<p>发现更多活动，欢迎下载<a href='https://leyuan.page.link/app'>乐源APP</a></p>"
        const body10 = "<br><p><b>" + orgName + "</b></p>"
    
    
        const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + body06 + body07 + body08 + body09 + body10
        sendHTMLEmailTo(email,messageSubject,htmlString)
    }
    else if (lang == "en"){

        const messageSubject = "Event from <" + orgName + ">"+" has been scheduled by your friend <"+friendName+"> - "+eventTitle
    
        const htmlSubject = "<h2>" + orgName + " event has been scheduled successfully</h2>"
        const body01 = "<p>Hello " + userName + ",</p><br>"
        const body02 = "<p>You have been successfully scheduled event <b>" + eventTitle + "</b>. Please note your ticket ID is「" + ticketId + "」</p>"
        const body03 = "<p>The following is the event information</p>"
        const body04 = "<p>Date/Time: <b>" + eventStartDate + "</b></p>"
        const body05 = "<p>Address: <b>" + eventAddress + "</b></p>"
        const body06 = "<p>Ticket: <b>" + ticketId + "</b></p>"
        const body07 = "<p>Your friend: <b>" + friendName + "</b>, friend's email address: <b>"+friendEmail+"</b></p>"
        const body08 = "<p>Please visit <a href='https://events.letjoy.app/" + eventId + '/' + lang  +"'>Event Link</a></p><br>"
        const body09 = "<p>Discover more event like this，please download <a href='https://leyuan.page.link/app'>Letjoy APP</a></p>"
        const body10 = "<br><p><b>" + orgName + "</b></p>"
    
    
        const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + body06 + body07 + body08 + body09 + body10
        sendHTMLEmailTo(email,messageSubject,htmlString)
    }
    else{
        
        const messageSubject = "您的朋友 "+friendName+" 已帮您预约活动 - "+eventTitle
    
        const htmlSubject = "<h2>" + orgName + "的活动已成功预约，如有需要请联系<b>"+friendName+"</b></h2>"
        const body01 = "<p>您好 " + userName + ",</p><br>"
        const body02 = "<p>您的活动<b>" + eventTitle + "</b>已成功预约，请认准本次的门票ID「" + ticketId + "」</p>"
        const body03 = "<p>以下是本次活动的详细信息</p>"
        const body04 = "<p>时间: <b>" + eventStartDate + "</b></p>"
        const body05 = "<p>地址: <b>" + eventAddress + "</b></p>"
        const body06 = "<p>门票: <b>" + ticketId + "</b></p>"
        const body07 = "<p>您的朋友: <b>" + friendName + "</b> 电子邮箱 <b>"+friendEmail+"</b></p>"
        const body08 = "<p>详细内容请点击<a href='https://events.letjoy.app/" + eventId  +"'>活动链接</a></p><br>"
        const body09 = "<p>发现更多活动，欢迎下载<a href='https://leyuan.page.link/app'>乐源APP</a></p>"
        const body10 = "<br><p><b>" + orgName + "</b></p>"


    const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + body06 + body07 + body08 + body09 + body10
    sendHTMLEmailTo(email,messageSubject,htmlString)
    }
}

export function sendEventTicketPurchaseEmail(email,eventTitle,eventId,userName,eventPrice,paymentEmail,currency,orgName){
    const messageSubject = "<" + orgName + ">"+"的活动已成功预约 - "+eventTitle
    const htmlSubject = "<h2>" + orgName + "的活动已成功预约</h2>"
    const body01 = "<p>您好 " + userName + ",</p><br>"
    const body02 = "<p>您的活动<b>" + eventTitle + "</b>已成功预约，请您按照下面的提示完成E-Transfer转账</p>"
    const body03 = "<p><b>以下是本次活动的E-Transfer信息</b></p>"
    const body04 = "<p>转账邮箱: <b>" + paymentEmail + "</b></p>"
    const body05 = "<p>门票价格: <b>" + eventPrice + ' ' + currency + "</b></p>"
    const body06 = "<p>EMT请一定备注报名邮箱和姓名: <b>" + email + "</b></p>"
    const body07 = "<p>详细内容请点击<a href='https://events.letjoy.app/" + eventId  +"'>活动链接</a></p><br>"
    const body08 = "<p>发现更多活动，欢迎下载<a href='https://leyuan.page.link/app'>乐源APP</a></p>"
    const body09 = "<br><p><b>" + orgName + "</b></p>"

    const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + body06 + body07 + body08 + body09
    sendHTMLEmailTo(email,messageSubject,htmlString)
}

export function sendEventTicketEMTPaymentEmail(email,eventTitle,eventId,emtEmail,eventPrice,eventDate,eventAddress,userName,orgName,lang,quantity){
    if (lang == 'zh'){
        const messageSubject = "<" + orgName + ">"+"的活动已成功预约 - 请您尽快按如下信息支付款项， 以锁定席位"
        const htmlSubject = "<h2>这不是门票!!! " + orgName + "的活动已成功预约，请您尽快按如下信息支付款项， 以锁定席位</h2>"
        const body01 = "<p>您好 " + userName + ",</p><br>"
        const body02 = "<p>您的活动<b>" + eventTitle + "</b>已成功预约，请您按照如下方式付款</p>"
        const body03 = "<p>以下是本次活动的信息</p>"
        const body04 = "<p>时间: <b>" + eventDate + "</b></p>"
        const body05 = "<p>地址: <b>" + eventAddress + "</b></p><p>数量: <b>" + quantity + "</b></p><p>票价: <b>$" + eventPrice + "CAD</b></p>"
        const body06 = "<p><b>以下是本次活动的付款信息</b></p>"
        const body07 = "<p>付款电子邮箱: <b>" + emtEmail + "</b></p>"
        const body08 = "<p>门票总价: <b>$" + eventPrice * quantity + 'CAD' + "</b></p>"
        const body09 = "<p><b>EMT请一定备注报名邮箱和姓名</b>: <b>" + email + "</b> <b>" + userName + "</b></p>"
        const body10 = "<p>按照如上方式付款完成，请您回到活动界面确认，如果您已经支付，活动方会发送一封确认邮件给您。详细内容请点击<a href='https://evtgo.com/" + eventId  +"'>活动链接</a></p><br>"
        const body11 = ""
        const body12 = "<br><p><b>" + orgName + "</b></p>"
        const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + body06 + body07 + body08 + body09 + body10 + body11 + body12
        sendHTMLEmailTo(email,messageSubject,htmlString)
    }
    else{
        const messageSubject = "<" + orgName + ">"+" Event is confirmed - Please pay EMT ASAP to hold your spot"
        const htmlSubject = "<h2>THIS IS NOT TICKET!!! " + orgName + "'s event is confirmed. Please arrange your Email Transfer using following information ASAP to hold your spot.</h2>"
        const body01 = "<p>Hello " + userName + ",</p><br>"
        const body02 = "<p>Your event <b>" + eventTitle + "</b> is confirmed, Please arrange your Email Transfer now. </p>"
        const body03 = "<p>The following is the information of event</p>"
        const body04 = "<p>Date: <b>" + eventDate + "</b></p>"
        const body05 = "<p>Address: <b>" + eventAddress + "</b></p><p>Quan: <b>" + quantity + "</b></p><p>Ticket price: <b>$" + eventPrice + "CAD</b></p>"
        const body06 = "<p><b>The following is the information of EMT payment</b></p>"
        const body07 = "<p>Email transfer's email: <b>" + emtEmail + "</b></p>"
        const body08 = "<p>Ticket Total price: <b>$" + eventPrice * quantity + 'CAD' + "</b></p>"
        const body09 = "<p>EMT Notes: (Please use this as note section): <b>" + email + "</b> <b>" + userName + "</b></p>"
        const body10 = "<p>If you have paid us already, please check back in, we will send you confirmation email. For more information <a href='https://evtgo.com/" + eventId  +"'>Click Event Link</a></p><br>"
        const body11 = ""
        const body12 = "<br><p><b>" + orgName + "</b></p>"
        const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + body06 + body07 + body08 + body09 + body10 + body11 + body12
        sendHTMLEmailTo(email,messageSubject,htmlString)
    }
    
}

export function sendEventTicketStripePaymentEmail(email,eventTitle,eventId,ticketId,eventPrice,eventDate,eventAddress,userName,orgName,quan){
    const messageSubject = "<" + orgName + ">"+"的活动已成功出票 - "+eventTitle
    const htmlSubject = "<h2>" + orgName + "的活动已成功出票</h2>"
    const body01 = "<p>您好 " + userName + ",</p><br>"
    const body02 = "<p>您的活动<b>" + eventTitle + "</b>已成功出票，您的门票号码为「" + ticketId + "」</p>"
    const body03 = "<p>以下是本次活动的信息</p>"
    const body04 = "<p>时间: <b>" + eventDate + "</b></p>"
    const body05 = "<p>地址: <b>" + eventAddress + "</b></p>"
    const body06 = "<p>门票号码: <b>" + ticketId + "</b></p>"
    const body07 = "<p>已付款: <b>$" + (Math.round(eventPrice * quan * 100) / 100) + 'CAD' + "</b></p>"
    const body08 = "<p>数量: <b>" + quan + "</b></p>"
    const body09 = "<h4>详细内容请点击<a href='https://evtgo.com/" + eventId  +"'>活动链接</a>并于活动当天展示<a href='https://evtgo.com/ticket-id/" + ticketId + "'>此活动门票</a>签到</h4><br>"
    const body10 = "<p>发现更多活动，欢迎下载<a href='https://leyuan.page.link/app'>乐源APP</a></p>"
    const body11 = "<br><p><b>" + orgName + "</b></p>"
    const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + body06 + body07 + body08 + body09 + body10 + body11
    sendHTMLEmailTo(email,messageSubject,htmlString)
}

export function sendEventLuckyDrawSubmissionEmail(email,eventTitle,eventId,userName,sponsorName,ticketId,orgName,lang){
    if (lang == "zh"){
        const messageSubject = "您已参加<" + eventTitle + ">"+"的抽奖活动"
    const htmlSubject = "<h2>" + eventTitle + "的抽奖活动已成功报名(这不是门票)</h2>"
    const body01 = "<p>您好 " + userName + ",</p><br>"
    const body02 = "<p>本次抽奖活动由<b>" + sponsorName + "</b>提供赞助和服务，请于活动当天前往现场参加抽奖</p>"
    const body03 = "<p>以下是本次抽奖活动的信息</p>"
    const body04 = "<p>抽奖提供方: <b>" + sponsorName + "</b></p>"
    const body05 = "<p>抽奖号码: <b>" + ticketId + "</b></p>"
    const body06 = "<p>详细内容请点击<a href='https://events.letjoy.app/" + eventId  +"'>活动链接</a></p><br>"
    const body07 = "<p>发现更多活动，欢迎下载<a href='https://leyuan.page.link/app'>乐源APP</a></p>"
    const body08 = "<br><p><b>" + orgName + "</b></p>"

    const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + body06 + body07 + body08
    sendHTMLEmailTo(email,messageSubject,htmlString)
    }
    else{
        const messageSubject = "You have joined lucky draw event"
    const htmlSubject = "<h2>You have joined lucky draw event</h2>"
    const body01 = "<p>Hello " + userName + ",</p><br>"
    const body02 = "<p>Our lucky draw event is sponsored by <b>" + sponsorName + "</b>. Please arrived at our location on time.</p>"
    const body03 = "<p>The followings are the information of this event</p>"
    const body04 = "<p>Sponsored by: <b>" + sponsorName + "</b></p>"
    const body05 = "<p>Lucky draw ticket ID: <b>" + ticketId + "</b></p>"
    const body06 = "<p>For more information, please follow <a href='https://events.letjoy.app/" + eventId  +"'>Event Link</a></p><br>"
    const body07 = "<p>Discover more event, please download <a href='https://leyuan.page.link/app'>LETJOY APP</a></p>"
    const body08 = "<br><p><b>" + orgName + "</b></p>"

    const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + body06 + body07 + body08
    sendHTMLEmailTo(email,messageSubject,htmlString)
    }
}

export function sendEventCheckinEmail(email,eventTitle,eventId,userName){
    const messageSubject = "活动已完成签到 - "+eventTitle
    const messageText0 = "您好 "+userName+"，\n\n您的活动<"+eventTitle+">已完成签到\n\n以下是本次活动的详细信息\n\n"
    const messageText1 = "详细内容请点击\nhttps://events.letjoy.app/"+eventId
    const messageText2 = "\n\n\n如果您想了解更多，请登录我们的网站 https://events.letjoy.app \n或者下载我们的app https://leyuan.page.link/app\n\n\n\n乐源团队"
    sendEmailTo(email,messageSubject,messageText0+messageText1+messageText2)
}

export function sendWelcomeEmail(email, userName){
    const messageSubject = "Welcome to EventGo"
    const messageText0 = "Hello "+userName+", \n\nWelcome aboard to EventGo! People using EventGo to explore events in their area.\n\nYou can join amazing events hosted by amazing group of people, or explore interests with others together.\n\n"
    const messageText1 = "Please let me know if you need helps or you can email us at \n\ncontact@evtgo.com\n\n"
    const messageText2 = "\n\nYours truly,\n\nEventGo"
    sendEmailTo(email,messageSubject,messageText0+messageText1+messageText2)
}



export function createOnboardNotification(userUID,userName){
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    if (userUID != ""){
        var notificationSet = {
            fromUID: 'system',
            toUID: userUID,
            status: 'sent',
            type: 'welcome',
            message: 'Hello '+userName+',\nwelcome aboard to EventGo! \n\nWe are here to help you if you need anything! Please let us know what you need, you can send us email to contact@evtgo.com',
            timestamp: timestampNow,
        }
        fires.collection('notifications').add(notificationSet)
    }
}

export function createSystemLoginNotification(userUID){
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    if (userUID != ""){
        var notificationSet = {
            fromUID: 'system',
            toUID: userUID,
            status: 'sent',
            type: 'login',
            loginUA: window.navigator.userAgent.toLowerCase(),
            message: 'You have logged in from website at ' + (new Date()).toLocaleString('en') + ' login info: ' +  window.navigator.userAgent.toLowerCase(),
            timestamp: timestampNow,
        }
        fires.collection('notifications').add(notificationSet)
    }
}

export function sendEmailTo(email, messageSubject, messageTexts){
    const messageObj = {
        'subject':messageSubject, 
        'text':messageTexts
    }
    var emailObj = []
    emailObj.push(email)
    fires
    .collection('mail')
    .add({
        'to':emailObj,
        'message':messageObj
    }).then((docRef)=>{
        const sentId = docRef.id
    })
}

export function sendHTMLEmailTo(email, messageSubject, htmlTexts){
    const messageObj = {
        'subject':messageSubject, 
        'html':htmlTexts
    }
    var emailObj = []
    emailObj.push(email)
    fires
    .collection('mail')
    .add({
        'to':emailObj,
        'message':messageObj
    }).then((docRef)=>{
        const sentId = docRef.id
    })
}

//event details
export function fetchEventDetailsByEventId(eventId){
    //Parameters: eventId is string
    //Return: promise with result
    if (eventId != ""){
        fires
        .collection("events")
        .doc(eventId)
        .get()
        .then((snapshot)=>{
            const eventData = snapshot.data();
            //event data is dictionary
        })
    }
}

//join event after signin/signup
export function joinEventByEventIdAuthorized(eventId, uid){
    //eventId is event's firestore ID, uid is user's uid, eventInfo is the eventData
    //this method allowed authorized user to join event

    //Parameters: eventId is string, uid is string, both non-null
    //Return: promise with result
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    if (uid != "" && eventId != ""){
        fires
        .collection("users")
        .doc(uid)
        .get()
        .then((snapshotA) => {
          if (snapshotA.exists) {
            const usersData = snapshotA.data();
            const usersName = usersData.name;
            const usersWork = usersData.work;
            const usersWechat = usersData.wechat;
            const userEmail = usersData.email
            fires
              .collection("users")
              .doc(uid)
              .update({ currentEvent: eventId, timestamp: timestampNow })
              .then(() => {
                fires
                  .collection("events")
                  .doc(eventId)
                  .collection("members")
                  .doc(userEmail)
                  .set({
                    name: usersName,
                    checkinStatus: false,
                    work: usersWork,
                    wechat: usersWechat,
                    email: userEmail,
                    timestamp: timestampNow,
                  })
                  .then(() => {
                    
                  });
              });
          } else {
            //user is not exist
            //login failed
          }
        });
    }
    else{
        //user uid or event id is empty
    }
    
}

//join event by guess
export function joinEventByEventIdGuess(eventId, email, usersName, usersWork, usersWechat){
    //eventId, email required
    //usersName, usersWork, usersWechat optional
    //Parameters: eventId, email, usersName, usersWork, usersWechat are string both non-null
    //Return: promise with result
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    if (eventId != "" && email != ""){
        fires
                  .collection("events")
                  .doc(eventId)
                  .collection("members")
                  .doc(email)
                  .set({
                    name: usersName,
                    checkinStatus: false,
                    work: usersWork,
                    wechat: usersWechat,
                    email: email,
                    timestamp: timestampNow,
                  })
                  .then(() => {
                    
                  });
    }
    else{
        //return error, event id or email empty
    }
}



//payment
export async function payTicketPayment(
    uid, 
    priceId, 
    eventId, 
    price, 
    quantity, 
    ticketName, 
    ticketItemId, 
    eventName, 
    calculateTax,
    userName,
    userEmail,
    dateSelected,
    userAgent,
    orgId,
    applyCoupon){
    const docRef = await fires
    .collection('service-stripe-customers')
    .doc(uid)
    .collection("checkout_sessions")
    .add({
        mode: "payment",
        automatic_tax: calculateTax,
        quantity: quantity,
        price: priceId,
        eventId: eventId,
        allow_promotion_codes: applyCoupon,
        success_url: window.location.origin + '/payment-success/'+eventId,
        cancel_url: window.location.origin + '/payment-cancel/'+eventId,
        metadata: {
            eventId: eventId,
            eventName: eventName,
            orgId: orgId,
            price: price,
            quantity: quantity,
            priceId: priceId,
            userUID: uid,
            fromUA: userAgent,
            registerEmail: userEmail,
            registerName: userName,
            dateSelected: dateSelected,
            ticketName: ticketName,
            ticketItemId: ticketItemId,
            calculateTax: calculateTax,
        },
    })
    docRef.onSnapshot((snap)=>{
        const { error, url } = snap.data();
        if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
        }
        if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
        }
    })
}

export async function payShoppingCartTourTicket(
    uid, 
    eventId, 
    eventName, 
    calculateTax,
    userName,
    userFirstName,
    userLastName,
    cart_items,
    payment_items,
    userEmail,
    dateSelected,
    userAgent,
    orgId,
    applyCoupon, 
    uniqueId){
        const docRef = await fires
    .collection('service-stripe-customers')
    .doc(uid)
    .collection("checkout_sessions")
    .add({
        mode: "payment",
        automatic_tax: calculateTax,
        eventId: eventId,
        line_items: payment_items,
        allow_promotion_codes: applyCoupon,
        success_url: `${API_ROOT_URL}v1EventsAPIIssueTourTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}`,
        cancel_url: window.location.origin + '/payment-cancel/'+eventId,
        metadata: {
            eventId: eventId,
            eventTitle: eventName,
            orgId: orgId,
            eventName: eventName,
            firstName: userFirstName,
            lastName: userLastName,
            userUID: uid,
            success_url: `${API_ROOT_URL}v1EventsAPIIssueTourTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}`,
            fromUA: userAgent,
            registerEmail: userEmail,
            registerName: userName,
            uniqueId: uniqueId,
            dateSelected: dateSelected,
            calculateTax: calculateTax,
            type: 'tour',
        },
    })
    docRef.onSnapshot((snap)=>{
        const { error, url } = snap.data();
        if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: could not purchase ticket`)
        }
        if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            // adding cart items into
            fires
            .collection('users')
            .doc(uid)
            .collection("carts")
            .doc(docRef.id)
            .set({
                eventId: eventId, 
                uniqueId: uniqueId,
                orgId: orgId,
                timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
                shoppingCart: cart_items
            }).then((sVal) => {
                window.location.assign(url);
            })
            
        }
    })

}

async function payShoppingCartIssueFreeTickets(
    uid, 
    eventId, 
    eventName, 
    calculateTax,
    userName,
    userFirstName,
    userLastName,
    cart_items,
    payment_items,
    userEmail,
    dateSelected,
    userAgent,
    orgId,
    uniqueId,
    requestId,
    promo,
    promoStripe){

        fetch(`${API_ROOT_URL}v1EventsAPIIssueFreeTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}&first_name=${userFirstName}&last_name=${userLastName}&email=${userEmail}`,{
            method: 'POST',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
            },
        }).then(response => response.json())
        .then((data) => {
            let dataObj = {
                status: 'failure',
                success: false,
                orderId: '',
                ticketId: '',
            }
            dataObj = data
            if (dataObj.success && dataObj.ticketId != ""){
                window.location.href = "/ticket-id/" + dataObj.ticketId
            }
            else {
                window.location.href = "/"+eventId
            }
        })


    }

export async function payShoppingCartFreePayment(
    uid, 
    eventId, 
    eventName, 
    calculateTax,
    userName,
    userFirstName,
    userLastName,
    cart_items,
    payment_items,
    userEmail,
    dateSelected,
    userAgent,
    orgId,
    uniqueId,
    requestId,
    promo,
    promoStripe){

        fires
            .collection('users')
            .doc(uid)
            .collection("carts")
            .doc(uniqueId)
            .set({
                eventId: eventId, 
                uniqueId: uniqueId,
                timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
                shoppingCart: cart_items,
                status: 'pending',
                promoCode: promo,
                requestId: requestId,
                orgId: orgId,
            }).then((sVal) => {
                fetch(`${API_ROOT_URL}v1EventsAPIIssueMultipleFreeTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}&coupon=${promo}&first_name=${userFirstName}&last_name=${userLastName}&email=${userEmail}`,{
                    method: 'POST',
                    headers: {
                      'Access-Control-Allow-Origin': '*',
                      'Content-Type': 'application/json',
                    },
                }).then(response => response.json())
                .then((data) => {
                    let dataObj = {
                        status: 'failure',
                        success: false,
                        orderId: '',
                        ticketId: '',
                    }
                    dataObj = data
                    if (dataObj.success && dataObj.ticketId != ""){
                        window.location.href = "/ticket-id/" + dataObj.ticketId
                    }
                    else {
                        window.location.href = "/"+eventId
                    }
                })
            })


    }

export async function payShoppingCartPayment(
    uid, 
    eventId, 
    eventName, 
    calculateTax,
    userName,
    userFirstName,
    userLastName,
    cart_items,
    payment_items,
    userEmail,
    dateSelected,
    userAgent,
    orgId,
    uniqueId,
    requestId,
    promo,
    promoStripe){

    const docRef = await fires
    .collection('service-stripe-customers')
    .doc(uid)
    .collection("checkout_sessions")
    .add({
        mode: "payment",
        automatic_tax: false,
        eventId: eventId,
        line_items: payment_items,
        promotion_code: promoStripe,
        success_url: `${API_ROOT_URL}v1EventsAPIIssueMultipleTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}&coupon=${promo}`,
        cancel_url: window.location.origin + '/payment-cancel/'+eventId,
        metadata: {
            eventId: eventId,
            eventTitle: eventName,
            orgId: orgId,
            eventName: eventName,
            firstName: userFirstName,
            lastName: userLastName,
            userUID: uid,
            fromUA: userAgent,
            registerEmail: userEmail,
            registerName: userName,
            uniqueId: uniqueId,
            dateSelected: dateSelected,
            calculateTax: calculateTax,
            requestId: requestId,
            promoCode: promo,
            promoStripe: promoStripe,
            url: `${API_ROOT_URL}v1EventsAPIIssueMultipleTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}&coupon=${promo}`,
        },
    })
    docRef.onSnapshot((snap)=>{
        const { error, url } = snap.data();
        if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            let freeItems = 0
            cart_items.forEach((cItem) => {
                if (cItem.priceType == "FREE" && cItem.price == 0){
                    freeItems += 1
                }
            })
            if (freeItems == cart_items.length && freeItems > 0) {
                //free checkout
                payShoppingCartIssueFreeTickets(
                    uid, 
                    eventId, 
                    eventName, 
                    calculateTax,
                    userName,
                    userFirstName,
                    userLastName,
                    cart_items,
                    payment_items,
                    userEmail,
                    dateSelected,
                    userAgent,
                    orgId,
                    uniqueId,
                    requestId,
                    promo,
                    promoStripe
                )
            }
            else{
                alert(`An error occured: could not purchase ticket`)
                window.location.href = "/event-registration/"+eventId
            }
            
        }
        if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            // adding cart items into
            fires
            .collection('users')
            .doc(uid)
            .collection("carts")
            .doc(uniqueId)
            .set({
                eventId: eventId, 
                uniqueId: uniqueId,
                timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
                shoppingCart: cart_items,
                status: 'pending',
                promoCode: promo,
            }).then((sVal) => {
                window.location.assign(url);
            })
            
        }
    })
}

//check in event
export function checkinEventByEventId(eventId, email, pin, pinEntered){
    //eventId, email required
    //pin needs to be matched
    //Parameters: eventId, email, pin, pinEntered are string both non-null
    //Return: promise with result
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    if (eventId != "" && email != ""){
        if (pin == pinEntered){
            //pin matched
            fires
                  .collection("events")
                  .doc(eventId)
                  .collection("members")
                  .doc(email)
                  .update({
                    checkinStatus: true,
                    email: email,
                    timestamp: timestampNow,
                  })
                  .then(() => {
                    
                  });
        }
        else{
            //pin not correct
        }
    }
    else{
        //return error, event id or email empty
    }
}


export function fetchEventMembersByEventId(eventId){
    if (eventId != ""){
        fires
        .collection("events")
        .doc(eventId)
        .collection("members")
        .get()
        .then((snapshot)=>{
            snapshot.docs.map((memberItm) => {
                //member id could be a email or uid
                const memberId = memberItm.id
                const memberData = memberItm.data()
            })
        })
    }
}

export function fetchEventMessagesByEventId(eventId){
    if (eventId != ""){
        fires
        .collection("events")
        .doc(eventId)
        .collection("messages")
        .get()
        .then((snapshot)=>{
            snapshot.docs.map((messageItm) => {
                //message 留言
                const messageId = messageItm.id
                const messageData = messageItm.data()
            })
        })
    }
}

export function createEventMessageByEventId(eventId, email, name, messageDetail, profileURL){
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

    if (eventId != "" && messageDetail != ""){
        const newMessage = {
            email: email, 
            name: name, 
            message: messageDetail, 
            profileURL: profileURL, 
            timestamp: timestampNow}
        fires
        .collection("events")
        .doc(eventId)
        .collection("messages")
        .add(newMessage)
    }
}

export function createEventPictureByEventId(eventId, email, name, pictureURL, profileURL){
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    if (eventId != "" && pictureURL != ""){
        const newPicture = {
            email: email, 
            name: name, 
            imageURL: pictureURL,
            profileURL: profileURL, 
            timestamp: timestampNow}
        fires
        .collection("events")
        .doc(eventId)
        .collection("pictures")
        .add(newPicture)
    }
}

export function fetchEventPicturesByEventId(eventId){
    if (eventId != ""){
        fires
        .collection("events")
        .doc(eventId)
        .collection("pictures")
        .get()
        .then((snapshot)=>{
            snapshot.docs.map((picItm) => {
                //picture 图片
                const picId = picItm.id
                const picData = picItm.data()
            })
        })
    }
}

export function eventMemberActionThumbupByEventId(eventId, email, thumbup_bool){
    // 点赞 thumbup_bool是true false
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    if (eventId != "" && email != ""){
        fires
        .collection("events")
        .doc(eventId)
        .collection("members")
        .doc(email)
        .update({
            thumbup: thumbup_bool, 
            thumbupTimestamp: timestampNow})
    }
}

